import React from "react";
import { Box, Typography } from "@mui/material";
import { StyledAuthHeader } from "../micro/micro.styled";

import DensityLogo from "../../assets/Density-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { ArrowBackIos } from "@mui/icons-material";

interface WaitlistHeaderProps {
  step: number;
  showBackButton?: boolean;
  backButtonHandler: () => void;
  handleStep: (stepNumber: number) => void;
}

const WaitlistHeader = (props: WaitlistHeaderProps): JSX.Element => {
  const { step, backButtonHandler, showBackButton = true, handleStep } = props;
  const navigate = useNavigate();
  const steps = [0, 1, 2];

  const handleStepSelection = (stepNumber = 0) => {
    handleStep(stepNumber);
  };
  return (
    <StyledAuthHeader sx={{ paddingBottom: "15px" }}>
      <Box>
        {showBackButton && (
          <Box
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: "30px",
            }}
            onClick={backButtonHandler}
          >
            <ArrowBackIos />
          </Box>
        )}
        <Link to={"/"}>
          <img
            style={{
              maxWidth: "110px",
              objectFit: "contain",
              cursor: "pointer",
              marginLeft: showBackButton ? "45px" : "0",
            }}
            src={DensityLogo}
            alt={"Density"}
          />
        </Link>
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "10px",
          gap: "10px",
          justifyContent: "space-between",
          maxWidth: "400px",
        }}
        mt={2}
        mx={"auto"}
      >
        {steps.map((stepNumber: number) => (
          <Box
            onClick={() => handleStepSelection(stepNumber)}
            key={stepNumber}
            sx={{
              py: "8px",
              width: "33%",
              borderRadius: "4px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "3px",
            }}
          >
            <Box
              sx={{
                height: "3px",
                width: "100%",
                flexShrink: 0,
                borderRadius: "4px",
                cursor: "pointer",
                backgroundColor: stepNumber <= step ? "#fcfcfc" : "#42444a",
              }}
            ></Box>
          </Box>
        ))}
      </Box>
    </StyledAuthHeader>
  );
};

export default WaitlistHeader;
