import React, { useEffect, useMemo, useState } from "react";
import { useUserContext } from "../../context/UserContext";
import { useSnackBar } from "../../context/SnackBar";
import {
  StyledDashContainer,
  StyledPlansWrapper,
  StyledSinglePlan,
} from "../../components/micro/micro.styled";
import { Box, Typography, Button } from "@mui/material";
import { AxiosResponse } from "axios";
import { useDispatch } from "react-redux";

import { analyticsEvent } from "../../redux/actions/analytics";
import { api } from "../../api/base";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Content from "../../components/micro/Content";

import { SinglePlanItem } from "../../types/subscription";
import CheckoutModal from "../../components/CheckoutModal";

const PlansPage = (): JSX.Element => {
  const [plans, setPlans] = useState<SinglePlanItem[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [url, setUrl] = useState<string>("");
  const { setPlan, plan } = useUserContext();
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();

  const getPlans = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> =
        await api.get("chargebee/plans");
      setPlans(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getSubscriptions = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.get(
        "chargebee/subscriptions",
      );
      setPlan({ planId: data.id, planName: data.item_price_id });
    } catch (err) {
      console.log(err);
    }
  };

  const buyPlan = async (plan: SinglePlanItem): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/checkout",
        {
          item_price_id: plan.id,
          unit_price: plan.price,
        },
      );
      setUrl(data.url);
      getSubscriptions();
    } catch (err) {
      console.log(err);
    }
  };

  const updatePlan = async (props: SinglePlanItem): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/update",
        {
          planId: plan.planId,
          subscription_items: {
            item_price_id: props.id,
            quantity: 1,
            unit_price: props.price,
          },
        },
      );
      showSnackBar(data, "success");
      getSubscriptions();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handleCloseModal = () => {
    setOpenModal(false);
    setUrl("");
  };

  useMemo(() => {
    if (url) setOpenModal(true);
  }, [url]);

  return (
    <StyledDashContainer>
      <CheckoutModal
        error={""}
        open={openModal}
        url={url}
        handleClose={handleCloseModal}
      />
      <Header />
      <Box display={"flex"} height={"100%"} width={"100%"}>
        <Sidebar />
        <Content>
          <StyledPlansWrapper>
            {plans.map((item: SinglePlanItem, i: number) => {
              return (
                <StyledSinglePlan key={item.id + i}>
                  <Typography variant={"h5"}>{item.external_name}</Typography>
                  <Typography textAlign={"center"} variant={"body1"}>
                    Price: $ {(item.price / 100).toFixed(2)}
                  </Typography>
                  <Button
                    disabled={item.id === plan.planName}
                    onClick={() => {
                      plan.planName !== "" && plan.planName !== undefined
                        ? updatePlan(item)
                        : buyPlan(item);
                    }}
                    variant={"outlined"}
                  >
                    {plan.planName !== "" &&
                      plan.planName !== undefined &&
                      (item.id !== plan.planName
                        ? "Update plan"
                        : "Subscribed")}
                    {(plan.planName === "" || !plan.planName) && "Buy plan"}
                  </Button>
                </StyledSinglePlan>
              );
            })}
          </StyledPlansWrapper>
        </Content>
      </Box>
    </StyledDashContainer>
  );
};

export default PlansPage;
