import { Box, Typography } from '@mui/material';

import LaptopImage from '../../assets/laptop.png';
import LaptopGraphic from '../../assets/laptop+.png';
import PlusImage from '../../assets/+.png';
import DeckImage from '../../assets/deck 1.png';
import HardwareImage from '../../assets/hardware.png';

const CreateASet = () => {
  return (
    <Box>
      <Typography
        variant="subtitle2"
        align="center"
        sx={{
          background:
            'linear-gradient(169.82deg, rgba(112, 117, 196, 0.25) -82.29%, #FFFFFF 189.7%)',
          '-webkit-background-clip': 'text',
          '-webkit-text-fill-color': 'transparent',
        }}
        // background: ;
      >
        Three ways to create a set
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '20px',
          height: '430px',
          marginTop: '43px',
        }}
      >
        <Box
          sx={{
            flexBasis: '66%',
            background: 'rgba(15, 15, 15, .7)',
            height: '100%',
            borderRadius: '30px',
            paddingTop: '45px',
            overflow: 'hidden',
            posrition: 'relative',
          }}
        >
          <p
            style={{
              fontFamily: 'IBM Plex Mono',
              fontSize: '12px',
              marginTop: 0,
              fontWeight: '600',
              lineHeight: '16px',
              letterSpacing: '1.25px',
              textAlign: 'center',
              color: '#575F9E',
              marginBottom: '30px',
            }}
          >
            RECORD LIVE
          </p>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-evenly',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: 'center',
                  fontSize: '24px',
                  maxWidth: '326px',
                  margin: '0 auto',
                  lineHeight: '27.42px',
                  marginBottom: '16px',
                }}
              >
                With Density hardware
              </Typography>
              <p
                style={{
                  fontFamily: 'Inter',
                  color: '#7F849B',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '18px',
                  letterSpacing: '0.1px',
                  marginBottom: '24px',
                  maxWidth: '260px',
                  textAlign: 'center',
                }}
              >
                For club/festival set recording, connect the Density hardware to the decks.
              </p>
              <img style={{ height: '58px', objectFit: 'contain' }} src={HardwareImage} alt="" />
              <img
                style={{
                  height: '10px',
                  objectFit: 'contain',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
                src={PlusImage}
                alt=""
              />
              <img
                style={{
                  height: '140px',
                  width: '186px',
                  objectFit: 'contain',
                }}
                src={DeckImage}
                alt=""
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                borderRadius: '30px',
                paddingTop: '20px',
                paddingBottom: '20px',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  height: '120px',
                  width: '1px',
                  background: 'rgba(255, 255, 255, 0.1)',
                }}
              />
              <p
                style={{
                  fontFamily: 'Inter',
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '18px',
                  letterSpacing: '0.1px',
                  textAlign: 'center',
                  color: '#7F849B',
                }}
              >
                OR
              </p>
              <Box
                sx={{
                  height: '120px',
                  width: '1px',
                  background: 'rgba(255, 255, 255, 0.1)',
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{
                  textAlign: 'center',
                  fontSize: '24px',
                  maxWidth: '326px',
                  margin: '0 auto',
                  lineHeight: '27.42px',
                  marginBottom: '16px',
                }}
              >
                With laptop and decks
              </Typography>
              <p
                style={{
                  fontFamily: 'Inter',
                  color: '#7F849B',
                  fontSize: '12px',
                  fontWeight: 500,
                  lineHeight: '18px',
                  letterSpacing: '0.1px',
                  marginBottom: '24px',
                  maxWidth: '260px',
                  textAlign: 'center',
                }}
              >
                For home/studio set recording, connect a laptop to your decks.
              </p>
              <img style={{ height: '58px', objectFit: 'contain' }} src={LaptopGraphic} alt="" />
              <img
                style={{
                  height: '10px',
                  objectFit: 'contain',
                  marginTop: '10px',
                  marginBottom: '10px',
                }}
                src={PlusImage}
                alt=""
              />
              <img
                style={{
                  height: '140px',
                  width: '186px',
                  objectFit: 'contain',
                }}
                src={DeckImage}
                alt=""
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flexBasis: '33%',
            background: 'rgba(15, 15, 15, .7)',
            height: '100%',
            borderRadius: '30px',
            paddingTop: '60px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <p
              style={{
                fontFamily: 'IBM Plex Mono',
                color: '#575F9E',
                fontSize: '12px',
                fontWeight: 600,
                lineHeight: '16px',
                letterSpacing: '1.25px',
                marginBottom: '24px',
              }}
            >
              POINT AND CLICK
            </p>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: 'center',
                fontSize: '24px',
                maxWidth: '326px',
                margin: '0 auto',
                lineHeight: '27.42px',
                marginBottom: '16px',
              }}
            >
              With laptop only
            </Typography>
            <p
              style={{
                fontFamily: 'Inter',
                color: '#7F849B',
                fontSize: '12px',
                fontWeight: 500,
                lineHeight: '18px',
                letterSpacing: '0.1px',
                marginBottom: '24px',
                maxWidth: '200px',
                textAlign: 'center',
              }}
            >
              Point and click in the Density app. No decks required.
            </p>
            <img
              style={{
                width: '172px',
                display: 'block',
                height: '106px',
                objectFit: 'contain',
                marginTop: '40px',
              }}
              src={LaptopImage}
              alt=""
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateASet;
