import {
  styled,
  Box,
  BoxProps,
  Button,
  ButtonProps,
  TextField,
  TextFieldProps,
  MenuItem,
} from "@mui/material";
import PasswordChecklist, {
  ReactPasswordChecklistProps,
} from "react-password-checklist";

export const StyledAppContainer = styled(Box)<BoxProps>(({}) => ({
  height: "100%",
  width: "100%",
}));

export const StyledAuthContainer = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: 16,
  position: "relative",
  background: "linear-gradient(180deg, #17181E 0%, #121214 100%);",
  overflowY: "scroll",
}));

export const StyledDashContainer = styled(Box)<BoxProps>(({ theme }) => ({
  height: "100%",
  background: "linear-gradient(180deg, #17181E 0%, #121214 100%);",
  overflowY: "auto",
  borderTop: "1px solid #0d0c10",
  iframe: {},
  "&. payment-method-container": {
    justifyContent: "center",
  },
  [theme.breakpoints.down("md")]: {
    "& .payment-method-container": {
      justifyContent: "flex-start",
      iframe: {
        minHeight: "450px",
      },
    },
  },
}));

export const StyledContentContainer = styled(Box)<BoxProps>(({}) => ({
  height: "100%",
  width: "100%",
  padding: 16,
}));

export const StyledSubscription = styled(Box)<BoxProps>(({}) => ({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 16,
  alignItems: "flex-start",
  div: {
    display: "flex",
    alignItems: "flex-end",
    gap: 8,
    textTransform: "capitalize",
  },
}));

export const StyledModalContent = styled(Box)<BoxProps>(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: "50%",
  transform: "translateX(-50%)",
  width: "100%",
  height: "100%",
  maxWidth: "100%",
  backgroundColor: "#f4f5f9",
  padding: 10,
  iframe: {
    height: "100%",
    width: "100%",
    border: "none",
    boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.15)",
  },
}));

export const StyledAuthInputs = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  maxWidth: "clamp(320px, 45vw, 600px)",
  padding: "48px 100px",
  width: "100%",
  textAlign: "center",
  boxShadow: "none",
  height: "100%",
  position: "relative",
  li: {
    alignItems: "center",
    gap: 8,
    svg: {
      marginRight: 0,
    },
  },
  a: {
    textDecoration: "none",
    textAlign: "center",
    color: theme.palette.primary.dark,
  },
  ul: {
    textAlign: "left",
    margin: 0,
  },
  span: {
    color: "#fff",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "20px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
}));

export const StyledAuthInputFields = styled(TextField)<TextFieldProps>(
  ({ theme }) => ({
    borderRadius: "4px",
    "& .Mui-error": {
      "& input": {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        boxShadow: "none",
      },
    },
    "&.password-fields": {
      "& .MuiInputBase-root": {
        paddingRight: "0",
      },
    },
    "& .MuiFormHelperText-root": {
      color: theme.palette.error.main,
      backgroundColor: "transparent",
      marginLeft: 0,
    },
    "& fieldset": {
      border: "none",
    },
    input: {
      borderRadius: "4px",
      boxShadow: "none",
      "-webkit-background-clip": "text",
      "&:-webkit-autofill": {
        boxShadow: "none",
      },
      "&:-internal-autofill-selected": {
        backgroundColor: "red !important",
      },
    },
    ":-webkit-autofill": {
      boxShadow: "none",
    },
  }),
);

export const StyledPlansWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  gap: 16,
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
}));

export const StyledSinglePlan = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  border: `solid 1px rgba(252, 252, 252, 0.10)`,
  padding: "48px 24px",
}));

export const StyledAuthHeader = styled(Box)<BoxProps>(({ theme }) => ({
  backgroundColor: "transparent",
  width: "100%",
  textAlign: "center",
  padding: "30px",
  img: {
    maxHeight: "40px",
    width: "auto",
    ":hover": {
      cursor: "pointer",
    },
  },
}));

export const StyledAccountContent = styled(Box)<BoxProps>(({ theme }) => ({
  width: "clamp(550px, 45vw, 700px)",
  border: `solid 1px rgba(252, 252, 252, 0.05)`,
  padding: "24px 42px",
  boxShadow: "0px 2px 1px 0px rgba(0,0,0, .25)",
  borderRadius: "8px",
  display: "flex",
  background: "#1c1d22",
  flexDirection: "column",
  gap: 32,
  [theme.breakpoints.down("md")]: {
    padding: "15px",
    maxWidth: "550px",
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: "10px",
  },
}));

export const StyledAccountActionButton = styled(Button)<ButtonProps>(
  ({ theme }) => ({
    color: "#575F9E",
    fontFamily: "IBM Plex Mono",
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: "1.25px",
    lineHeight: "16px",
  }),
);

export const StyledAccessGranted = styled(Box)<BoxProps>(({ theme }) => ({
  border: `solid 1px ${theme.palette.divider}`,
  padding: "13.5px 16px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  background: "#1B261C",
  maxWidth: "100%",
  gap: "30px",
}));

export const StyledPaymentButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: `solid 1px ${theme.palette.divider}`,
  padding: "19px",
  height: "58px",
  justifyContent: "flex-start",
  alignItems: "center",
  color: "rgba(255, 255, 255, 0.60)",
  textTransform: "unset",
}));

export const StyledCancelButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: "24px 32px",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  flexDirection: "column",
  background: "#1c1c20",
  textTransform: "unset",
  color: "#FCFCFC",
  border: "1px solid rgba(255, 255, 255, 0.05)",
  boxShadow: "0px 2px 1px 0px rgba(0, 0, 0, 0.25)",
  "&.active": {
    background: "#222327",
  },
  img: {
    "&.icon-img": {
      maxWidth: "22px",
      objectFit: "contain",
      marginRight: "32px",
      height: "22px",
    },
  },
}));
export const StyledExpectationButton = styled(Button)<ButtonProps>(
  ({ theme }) => ({
    justifyContent: "flex-start",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    textTransform: "unset",
    color: "rgba(252, 252, 252, 0.6)",
    "&.active": {
      background: "#222327",
      color: "#FCFCFC",
    },
    img: {
      "&.icon-img": {
        maxWidth: "22px",
        objectFit: "contain",
        marginRight: "32px",
        height: "22px",
      },
    },
    "& .Mui-checked": {
      color: "#575F9E",
    },
    "&.Mui-checked": {
      color: "#575F9E",
    },
  }),
);

export const StyledAuthButton = styled(Button)<ButtonProps>(({ theme }) => ({
  alignSelf: "center",
  marginTop: "auto",
  maxWidth: "400px",
  width: "100%",
  marginLeft: "40px",
  marginRight: "40px",
  padding: "15px",
  fontFamily: "IBM Plex Mono",
  marginBottom: "71px",
}));

export const StyledMenuItem = styled(MenuItem)<ButtonProps>(({ theme }) => ({
  justifyContent: "flex-end",
  fontFamily: "IBM Plex Mono",
  fontSize: "12px",
  fontWeight: 600,
  lineHeight: "16px",
  letterSpacing: "1.25px",
  padding: "8px 16px",
  color: "#FCFCFC",
  textTransform: "uppercase",
  "&:hover": {
    backgroundColor: "transparent",
    color: "rgba(252, 252, 252, 0.6)",
  },
}));

export const StyledAuthPlatformIcon = styled("img")<BoxProps>(({ theme }) => ({
  height: "20px",
  width: "20px",
  marginRight: "10px",
  objectFit: "contain",
}));

export const StyledPasswordChecklist = styled(
  PasswordChecklist,
)<ReactPasswordChecklistProps>(({ theme }) => ({
  li: {
    display: "flex",
    alignItems: "center",
    "&.valid": {
      span: {
        color: "#00BE00",
      },
    },
    "&.invalid": {
      span: {
        color: "rgba(252, 252, 252, 0.8)",
        opacity: 1,
      },
    },
    marginBottom: 15,
    span: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "11px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
    },
  },
}));
