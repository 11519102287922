import {
  ActionElementType,
  InfoCardProps,
} from "../../components/Info/InfoCardWithAction/types";
import { InfoPanelProps } from "../../components/Info/InfoPanel/types";
import { InfoshowcaseProps } from "../../components/Info/InfoShowcase/types";

interface LandingPageAltTexts {
  FIRST_INFO_CARD: InfoCardProps;
  SECOND_INFO_CARD: InfoCardProps;
  FIRST_INFO_PANEL: InfoPanelProps;
  FIRST_INFO_SHOWCASE: InfoshowcaseProps;
  SECOND_INFO_SHOWCASE: InfoshowcaseProps;
  THIRD_INFO_SHOWCASE: InfoshowcaseProps;
  FOURTH_INFO_SHOWCASE: InfoshowcaseProps;
  FIFTH_INFO_SHOWCASE: InfoshowcaseProps;
  SIXTH_INFO_SHOWCASE: InfoshowcaseProps;
}

const TEXTS: LandingPageAltTexts = {
  FIRST_INFO_CARD: {
    title:
      "The Density Hardware is the tool for capturing DJ sets in club and festival environments.",
    description:
      "Unlike traditional audio recordings, Density connects to your decks and mixer to log every detail of a performance - each cue, loop and knob twist. Once the set is done, open it in the Density DAW to see every track, transition, and automation lane broken out. It’s like getting a word document instead of a photo copy.",
    transparent: false,
    link: { url: "", text: "Watch how it works" },
  },
  SECOND_INFO_CARD: {
    title: "$420",
    description: [
      { text: "-Hardware - $300" },
      { text: "-Density Premium Annual Subscription - $120", },
      { text: "Total = $420" },
    ],
    transparent: false,
    link: { url: "/onboarding/1/density38", text: "purchase" },
    type: ActionElementType.Button,
  },
  FIRST_INFO_PANEL: {
    title: "The full setup",
    subtitle:
      "If you don’t have the Density Hardware, a laptop can be used to capture a set",
  },
  FIRST_INFO_SHOWCASE: {
    heading: "Superior to recording in audio",
    title: "Mastering-grade audio quality",
    description: "Density does not record ANY audio. It logs the actions the DJ takes during a set, similar to midi. When the set is opened in the DAW, it will ask you to locate your DJ folder. It will find the tracks and then it recreates the set. The result is a mastering-grade quality set. It’s not a recording of a recording that’s been run through a dirty mixer. "
  },
  SECOND_INFO_SHOWCASE: {
    title: "Remove Unreleased Tracks",
    description: "Quickly remove unreleased material that normally prevents a set from going out.",
  },
  THIRD_INFO_SHOWCASE: {
    title: "Never lose a set again",
    description: "The set is captured as soon as the DJ starts playing. Never forget to hit record again. The volume can be turned down in the DAW after so it will never distort.",
  },
  FOURTH_INFO_SHOWCASE: {
    title: "Ensure Consistent Loudness",
    description: "Mastering tools keep the volume consistent across the set so the listener isn’t forced to adjust the volume",
  },
  FIFTH_INFO_SHOWCASE: {
    title: "Rider Friendly",
    description: "The artist is the only person who can open the set, so they maintain complete control. This is permissible under many rider recording restrictions.",
  },
  SIXTH_INFO_SHOWCASE: {
    title: "Give it a try",
    description: "",
  }
};

export default TEXTS;
