import React, { ReactNode } from 'react';
import { StyledContentContainer } from './micro.styled';

type Props = {
  children: string | JSX.Element | JSX.Element[];
}

const Content = ({ children }: Props): JSX.Element => {
  return (
    <StyledContentContainer>
      {children}
    </StyledContentContainer>
  )
};


export default Content;
