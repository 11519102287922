import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import { StyledAuthButton, StyledAuthInputs } from "../micro/micro.styled";
import Waitlist from "../../assets/waitlist.png";

interface WaitlistCompletedProps {
  waitlistNumber: number;
}

const WaitlistCompleted = (props: WaitlistCompletedProps): JSX.Element => {
  const navigate = useNavigate();

  const handleContinue = () => {
    localStorage.removeItem("waitlist_step");
    localStorage.removeItem("onboarding_in_progress");
    localStorage.removeItem("user_email");
    localStorage.setItem("waitlistStep", "0");
    navigate("/");
  };
  return (
    <StyledAuthInputs>
      <img
        style={{
          width: "330px",
          height: "260px",
          objectFit: "contain",
          margin: "0 auto",
        }}
        src={Waitlist}
        alt={"waitlist"}
      />
      <Box
        sx={{
          maxWidth: "400px",
        }}
      >
        <Typography
          textAlign={"center"}
          fontSize={"20px"}
          variant={"h5"}
          mb={2}
          fontWeight={500}
          color={"rgba(252, 252, 252, 1)"}
        >
          You're on the waitlist!
        </Typography>
        <Typography
          fontSize={"12px"}
          fontWeight={500}
          textAlign={"center"}
          variant={"body1"}
          color={"rgba(252, 252, 252, 0.6)"}
          maxWidth={240}
          mx={"auto"}
          lineHeight={"18px"}
          letterSpacing={"0.1px"}
        >
          Your number{" "}
          <span style={{ color: "rgba(252, 252, 252, 0.80)" }}>
            {props.waitlistNumber}
          </span>{" "}
          on the waitlist. Touring DJs are given priority. We'll get back to you
          as soon as we review your profile.
        </Typography>
      </Box>
      <StyledAuthButton
        onClick={handleContinue}
        variant={"contained"}
        sx={{
          backgroundColor: "#42444C",
          color: "#FCFCFC",
          "&:hover": {
            color: "#121215",
          },
        }}
      >
        Got it
      </StyledAuthButton>
    </StyledAuthInputs>
  );
};

export default WaitlistCompleted;
