import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AxiosResponse } from "axios";
import { Box, Button, Typography } from "@mui/material";

import { useSnackBar } from "../../context/SnackBar";
import { useUserContext } from "../../context/UserContext";
import { api } from "../../api/base";
import {
  StyledAccountActionButton,
  StyledAccountContent,
  StyledAuthButton,
  StyledDashContainer,
} from "../../components/micro/micro.styled";
import Header from "../../components/Header";

import { SubscriptionClass, SinglePlanItem } from "../../types/subscription";
import CheckoutModal from "../../components/CheckoutModal";
import ChangeEmailModal from "../../components/modals/ChangeEmail";
import CancelPlanModal from "../../components/modals/CancelPlan";
import CancelPlanSuccessModal from "../../components/modals/CancelPlanSuccess";
import { useDispatch, useSelector } from "react-redux";
import { paymentDetailsChanged } from "../../redux/actions/app";
import PaymentDetailsChangedModal from "../../components/modals/PaymentDetailsChangedModal";
import ConfirmRenewalModal from "../../components/modals/ConfirmRenewal";
import ChangePasswordModal from "../../components/modals/ChangePassword";
import { analyticsEvent } from "../../redux/actions/analytics";

import DBunny from "../../assets/dbunny.png";
import UpgradeModal from "./modal/UpgradeModal";

export const formatDateFromTimestamp = (timestamp: number): string => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(timestamp * 1000);

  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return `${monthNames[monthIndex]} ${day}, ${year}`;
};

const ProfilePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { showSnackBar } = useSnackBar();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { setPlan, setUser, user } = useUserContext();
  const [subscriptions, setSubscriptions] = useState<SubscriptionClass>(
    {} as SubscriptionClass,
  );
  const [plans, setPlans] = useState<SinglePlanItem[]>([]);
  const [url, setUrl] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [price, setPrice] = useState<string>("");
  const [renewalModalOpen, setRenewalModalOpen] = useState<boolean>(false);
  const [changeEmailModalOpen, setChangeEmailModalOpen] = useState<boolean>(
    searchParams.get("email") === "change" || false,
  );
  const [changePasswordModalOpen, setChangePasswordModalOpen] =
    useState<boolean>(false);
  const [cancelPlanModalOpen, setCancelPlanModalOpen] =
    useState<boolean>(false);
  const [cancelPlanSuccessModalOpen, setCancelPlanSuccessModalOpen] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const blockingStatuses = ["payment_failed", "card_expired"];
  const [additionalText, setAdditionalText] = useState<string>("");

  const isPaymentModalOpen = useSelector(
    (state: any) => state.app.paymentDetailsChanged,
  );

  const handleCloseModal = (): void => {
    setOpenModal(false);
  };

  const handleClosePaymentDetailsModal = (): void => {
    dispatch(paymentDetailsChanged(false));
  };

  const getPlans = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> =
        await api.get("chargebee/plans");
      setPlans(data);
    } catch (err) {}
  };

  useEffect(() => {
    getPlans();
    if (searchParams.get("refresh_token") && searchParams.get("access_token")) {
      localStorage.setItem(
        "access_token",
        searchParams.get("access_token") || "",
      );
      localStorage.setItem(
        "refresh_token",
        searchParams.get("refresh_token") || "",
      );
      showSnackBar(
        "Successfully logged in!",
        "success",
        "center",
        2000,
        false,
        "auto",
        "95px",
      );
    } else {
      if (!localStorage.getItem("access_token")) {
        navigate("/");
      }
    }
    dispatch(
      analyticsEvent("PAGE_VISIT", "Account Management Visited", {
        page: "Account Management",
        email: user?.email,
      }),
    );
  }, []);

  useEffect(() => {
    if (plans.length > 0 && searchParams.get("fromDesktop") === "true") {
      buyPlan();
    }
    if (plans.length > 0 && searchParams.get("plan") === "hardware") {
      buyHardwarePlan();
    }
  }, [plans, searchParams]);

  const getProfile = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.get("auth/profile");
      setUser(data.user);
    } catch (err) {}
  };

  const getSubscriptions = async (): Promise<void> => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    try {
      const { data }: AxiosResponse<any, any> = await api.get(
        "chargebee/subscriptions",
      );
      setSubscriptions(data);
      setPlan({ planId: data.id, planName: data.item_price_id });
    } catch (err) {}
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };

  const lowerMyRate = async () => {
    if (!subscriptions?.id) {
      console.error("No subscription ID available.");
      return;
    }

    const plan =
      plans.find((plan: any) => plan.id === "Medium-USD-Monthly") || plans[0];

    try {
      // Checkout with the new plan
      const checkoutResponse = await api.post(
        "chargebee/change-subscription-plan",
        {
          subscriptionId: subscriptions.id,
          chargeBeeID: user.chargebeeID,
          newPlanId: plan.id,
        },
      );

      dispatch(
        analyticsEvent("CLICK", "Cancel-2.2: Clicked Lower My Rate", {
          email: user.email,
        }),
      );

      // Show success message
      showSnackBar(
        "Successfully changed subscription.",
        "success",
        "center",
        2000,
        false,
        "auto",
        "95px",
      );

      // Update local subscription data
      await getSubscriptions();
      setOpenModal(false);
    } catch (err: any) {
      // Improved error handling
      console.error("Error in changing subscription:", err);
      const errorMsg =
        err.response?.data?.error_msg ||
        "An error occurred during the subscription change.";
      showSnackBar(errorMsg, "error");
    }
  };

  const handleKeepSubscription = async () => {
    renewSubscription();
    dispatch(
      analyticsEvent("CLICK", "Cancel-4.2: Keep Subscription", {
        page: "Cancel Modal",
        email: user?.email,
      }),
    );
    setCancelPlanSuccessModalOpen(false);
  };

  const renewSubscription = async (): Promise<void> => {
    setRenewalModalOpen(false);
    try {
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/renew-subscription",
        {
          planId: subscriptions.id,
          trialEnd: subscriptions.trial_end,
          status: subscriptions.status,
        },
      );
      showSnackBar(
        "Successfully reactivated subscription.",
        "success",
        "center",
        2000,
        false,
        "auto",
        "95px",
      );
      getSubscriptions();
    } catch (err: any) {
      const { response } = err;
      showSnackBar(response.data.error_msg, "error");
    }
  };

  const handleSubscriptionRenewal = async (): Promise<void> => {
    renewSubscription();
  };

  const buyPlan = async (): Promise<void> => {
    try {
      const plan =
        plans.find((plan: any) => plan.id === "Premium-USD-Monthly") ||
        plans[0];
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/checkout",
        {
          item_price_id: plan.id,
          unit_price: plan.price,
          redirect_url: `${process.env.REACT_APP_URL}/account${searchParams.get("fromDesktop") ? "?fromDesktop=true" : ""}`,
        },
      );
      setUrl(data.url);
      setPrice("$15/month");
    } catch (err) {}
  };

  const buyHardwarePlan = async (): Promise<void> => {
    try {
      const { data }: AxiosResponse<any, any> = await api.post(
        "chargebee/checkout-hardware",
        {
          item_price_id: "Premium-USD-Yearly",
          unit_price: 1500,
          redirect_url: `${process.env.REACT_APP_URL}/account`,
        },
      );
      setUrl(data.url);
      setPrice("Subscription - $180/year");
      setAdditionalText("Hardware - $300 one time");
    } catch (err) {}
  };

  useEffect(() => {
    if (url) setOpenModal(true);
  }, [url]);

  useEffect(() => {
    if (searchParams.get("state") === "succeeded") {
      navigate(
        `/account/upgrade-success${searchParams.get("fromDesktop") ? "?fromDesktop=true" : ""}`,
      );
      dispatch(
        analyticsEvent("PAYMENT", "Payment Completed", {
          page: "Account Management",
          plan: "premium",
        }),
      );
    }

    if (searchParams.get("state") === "failed") {
      analyticsEvent("PAYMENT", "Payment failed", {
        page: "Account Management",
        plan: "premium",
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getProfile();
    getSubscriptions();
  }, []);

  const handleRenewal = async (): Promise<void> => {
    if (typeof subscriptions?.next_billing_at === "undefined") {
      const type =
        typeof subscriptions?.next_billing_at === "undefined"
          ? subscriptions?.status === "non_renewing"
            ? `Reactivate`
            : `Renew`
          : "";
      dispatch(
        analyticsEvent("CLICK", `${type} Pressed`, {
          page: "Account Management",
          email: user?.email,
        }),
      );
      handleRenewalConfirmation();
    } else {
      dispatch(
        analyticsEvent("CLICK", "Cancel-1: Clicked Cancel", {
          page: "Account Management",
          email: user?.email,
        }),
      );
      setCancelPlanModalOpen(true);
    }
  };

  const hasTrialExpired = () => {
    const trialEnd = new Date(subscriptions?.trial_end * 1000);
    const today = new Date();
    if (!subscriptions?.trial_end) return true;
    return today > trialEnd;
  };

  const handleRenewalConfirmation = () => {
    setRenewalModalOpen(true);
  };

  const checkUserStatus = (subscription: any, status: string): string => {
    if (blockingStatuses.includes(status)) {
      return status.replace("_", " ");
    }
    const planNames: any = {
      "Medium-USD-Monthly": "Artist",
      "Premium-USD-Monthly": "Premium",
      "Pro-USD-Monthly": "Pro",
      "PremiumHardware-USD-Yearly": "Premium Hardware",
    };
    const durationMatrix: any = {
      "Medium-USD-Monthly": "Monthly",
      "Premium-USD-Monthly": "Monthly",
      "Pro-USD-Monthly": "Monthly",
      "PremiumHardware-USD-Yearly": "Yearly",
    };
    return (
      `${subscription?.status?.replace("_", " ")} (${planNames[subscription?.item_price_id]} Plan, $${subscription.unit_price / 100}/${durationMatrix[subscription?.item_price_id]})` ||
      "Free plan"
    );
  };

  const handleCancelPlanSuccessModalClose = () => {
    dispatch(
      analyticsEvent("CLICK", "Cancel-4.1: Cancel pressed", {
        page: "Cancel Modal",
        email: user?.email,
      }),
    );
    setCancelPlanSuccessModalOpen(false);
  };

  const handleDownload = () => {
    dispatch(
      analyticsEvent("EVENT", "Download Density App", {
        "OS Downloaded": "Universal",
        location: "Account Management Page",
      }),
    );

    api
      .post("/auth/download", {
        email: user?.email,
        os: "universal",
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <StyledDashContainer>
      <Header />
      <Box
        gap={"24px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        width={"100%"}
        borderTop={"1px solid #0d0c10"}
        padding={"0 10px"}
        paddingBottom={"20px"}
      >
        <Typography
          mt={"62px"}
          variant="h2"
          color="rgba(252, 252, 252, 0.6)"
          alignSelf={"left"}
          marginBottom={"8px"}
          maxWidth={"clamp(550px, 45vw, 700px)"}
          width={"100%"}
        >
          Manage account
        </Typography>
        <StyledAccountContent>
          <Typography
            display={"flex"}
            justifyContent={"space-between"}
            variant="h2"
            color="#F3F3F5"
          >
            Account
          </Typography>
          <Box display={"flex"} alignItems={"flex-start"}>
            <Typography
              display={"flex"}
              variant="body1"
              fontSize={12}
              alignItems={"center"}
            >
              Email:&nbsp;
              <span style={{ color: "#fcfcfc" }}>{user?.email}</span>
            </Typography>
            <Box
              marginLeft={"auto"}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
            >
              <StyledAccountActionButton
                onClick={() => setChangeEmailModalOpen(true)}
                variant={"text"}
                sx={{ color: "#575F9E" }}
              >
                Change e-mail
              </StyledAccountActionButton>
              <StyledAccountActionButton
                onClick={() => setChangePasswordModalOpen(true)}
                variant={"text"}
                sx={{ color: "#575F9E" }}
              >
                Change password
              </StyledAccountActionButton>
            </Box>
          </Box>
        </StyledAccountContent>
        <StyledAccountContent>
          <Typography
            display={"flex"}
            justifyContent={"space-between"}
            variant="h2"
            color="#F3F3F5"
          >
            Subscription
          </Typography>
          {Object.keys(subscriptions).length === 0 ||
          subscriptions?.item_price_id === "Free-USD-Monthly" ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography
                display={"flex"}
                justifyContent={"space-between"}
                variant="body1"
                fontSize={12}
                alignItems={"center"}
              >
                Status: {loading ? "Loading..." : "Free plan"}
                {/* <StyledAccountActionButton
                  onClick={buyPlan}
                  variant={"text"}
                  sx={{ color: "#575F9E" }}
                >
                  Upgrade
                </StyledAccountActionButton> */}
              </Typography>

              <div
                style={{
                  display: loading ? "none" : "flex",
                  background:
                    "linear-gradient(264.82deg, #686FEF -4.46%, rgba(104, 111, 239, 0.25) 51.84%, rgba(104, 111, 239, 0) 108.77%)",
                }}
                className="flex w-full items-center justify-between rounded px-[32px] py-[42px]"
              >
                <div className="flex items-center">
                  <img
                    src={DBunny}
                    className="mr-[16px] h-[53px] w-[45px] object-contain"
                    alt=""
                  />
                  <div>
                    <p className="mb-[8px] font-PPRightGrotesk-SpatialBlack text-[18px] leading-[24px] tracking-[0.15px] text-[#E6E8F1]">
                      Density premium
                    </p>
                    <p className="text-[12px] font-medium leading-[18px] tracking-[0.1px] text-[#C1C5DD]">
                      Upgrade to remove the watermark
                    </p>
                  </div>
                </div>
                <StyledAuthButton
                  onClick={buyPlan}
                  variant={"contained"}
                  sx={{
                    m: 0,
                    width: 110,
                    background: "#C1C5DD",
                    px: "28px",
                    py: "10px",
                    fontWeight: 600,
                    color: "#141519",
                  }}
                >
                  Upgrade
                </StyledAuthButton>
              </div>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography
                alignItems={"center"}
                display={"flex"}
                justifyContent={""}
                variant="body1"
                fontSize={12}
              >
                Status:&nbsp;
                <span style={{ color: "#fcfcfc", textTransform: "capitalize" }}>
                  {loading
                    ? "Loading..."
                    : checkUserStatus(subscriptions, user?.status)}
                </span>
                <StyledAccountActionButton
                  onClick={() => handleRenewal()}
                  variant={"text"}
                  sx={{ color: "#575F9E", marginLeft: "auto" }}
                >
                  {typeof subscriptions?.next_billing_at === "undefined"
                    ? subscriptions?.status === "non_renewing"
                      ? `Reactivate`
                      : `Renew for $${subscriptions?.unit_price / 100}`
                    : "Cancel"}
                </StyledAccountActionButton>
              </Typography>
              {!hasTrialExpired() && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography
                    alignItems={"center"}
                    display={"flex"}
                    variant="body1"
                    fontSize={12}
                  >
                    Start date:&nbsp;
                    <span
                      style={{ color: "#fcfcfc", textTransform: "capitalize" }}
                    >
                      {formatDateFromTimestamp(subscriptions?.trial_start)}
                    </span>
                  </Typography>
                  <Typography display={"flex"} variant="body1" fontSize={12}>
                    Trial end date:&nbsp;
                    <span
                      style={{ color: "#fcfcfc", textTransform: "capitalize" }}
                    >
                      {formatDateFromTimestamp(subscriptions?.trial_end)}
                    </span>
                  </Typography>{" "}
                </Box>
              )}
              {!blockingStatuses.includes(user?.status) &&
              subscriptions?.next_billing_at ? (
                <Typography
                  alignItems={"center"}
                  display={"flex"}
                  variant="body1"
                  fontSize={12}
                >
                  Next payment date:&nbsp;
                  <span
                    style={{ color: "#fcfcfc", textTransform: "capitalize" }}
                  >
                    {formatDateFromTimestamp(subscriptions?.next_billing_at)}
                  </span>
                </Typography>
              ) : subscriptions?.status === "non_renewing" ? (
                <Typography
                  alignItems={"center"}
                  display={"flex"}
                  variant="body1"
                  fontSize={12}
                >
                  Active until:&nbsp;
                  <span
                    style={{ color: "#fcfcfc", textTransform: "capitalize" }}
                  >
                    {formatDateFromTimestamp(subscriptions?.current_term_end)}
                  </span>
                </Typography>
              ) : (
                <Typography
                  alignItems={"center"}
                  display={"flex"}
                  variant="body1"
                  fontSize={12}
                >
                  Subscription cancelled
                </Typography>
              )}
            </Box>
          )}
        </StyledAccountContent>
        <StyledAccountContent>
          <Typography
            alignItems={"center"}
            display={"flex"}
            justifyContent={"space-between"}
            variant="h2"
            color="#F3F3F5"
          >
            Payment method
            <Typography variant={"body1"} fontSize={12} ml={"auto"}>
              {user?.card
                ?.slice(8)
                .match(/.{1,4}/g)
                ?.join(" ")}
            </Typography>
            <StyledAccountActionButton
              onClick={() => navigate("payment-method")}
              variant={"text"}
              sx={{ color: "#575F9E", ml: 3 }}
            >
              Edit
            </StyledAccountActionButton>
          </Typography>
        </StyledAccountContent>
        <StyledAccountContent>
          <Typography
            alignItems={"center"}
            display={"flex"}
            justifyContent={"space-between"}
            variant="h2"
            color="#F3F3F5"
          >
            Download Density 
          </Typography>
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              display={"flex"}
              variant="body1"
              fontSize={12}
              alignItems={"center"}
            >
              Version:&nbsp;
              <span style={{ color: "#fcfcfc" }}>V0.1.198</span>
            </Typography>
            <a
              href="https://release.density.one/Density-Installer-Universal-Latest.dmg"
              download
            >
              <StyledAuthButton
                onClick={handleDownload}
                variant={"outlined"}
                sx={{
                  m: 0,
                  width: 148,
                  background: "rgba(255, 255, 255, 0.08)",
                  borderColor: "rgba(252, 252, 252, 0.5)",
                  letterSpacing: "0.5px",
                  fontSize: "11px",
                  color: "rgba(252, 252, 252, 0.6)",
                  fontWeight: 600,
                }}
              >
                Download
              </StyledAuthButton>
            </a>
          </Box>
        </StyledAccountContent>
      </Box>
      <ChangeEmailModal
        open={changeEmailModalOpen}
        handleClose={() => setChangeEmailModalOpen(false)}
        setUser={setUser}
      />
      <ChangePasswordModal
        open={changePasswordModalOpen}
        handleClose={() => setChangePasswordModalOpen(false)}
      />
      <CancelPlanModal
        open={cancelPlanModalOpen}
        handleClose={() => setCancelPlanModalOpen(false)}
        openSuccessModal={() => {
          setCancelPlanModalOpen(false);
          setCancelPlanSuccessModalOpen(true);
          getSubscriptions();
        }}
        lowerMyRate={lowerMyRate}
        planId={subscriptions?.id}
        subscription={subscriptions}
      />
      <CancelPlanSuccessModal
        subscriptions={subscriptions}
        open={cancelPlanSuccessModalOpen}
        handleClose={handleCancelPlanSuccessModalClose}
        renewSubscription={handleKeepSubscription}
      />
      <PaymentDetailsChangedModal
        open={isPaymentModalOpen}
        handleClose={handleClosePaymentDetailsModal}
        price={
          subscriptions?.unit_price
            ? `$${subscriptions?.unit_price / 100}`
            : `$0`
        }
      />
      <ConfirmRenewalModal
        open={renewalModalOpen}
        handleClose={() => setRenewalModalOpen(false)}
        handleRenewal={handleSubscriptionRenewal}
        subscriptionPrice={`$${subscriptions?.unit_price / 100}`}
        action={
          typeof subscriptions?.next_billing_at === "undefined"
            ? subscriptions?.status === "non_renewing"
              ? `Reactivate`
              : `Renew`
            : "Cancel"
        }
      />
      <UpgradeModal
        error={""}
        open={openModal}
        url={url}
        handleClose={handleCloseModal}
        price={price}
        additionalText={additionalText}
      />
    </StyledDashContainer>
  );
};

export default ProfilePage;
