interface link {
  url: string;
  text: string;
}

export enum ActionElementType {
  Link,
  Button,
}

export interface descriptionListItem {
  text: string;
  transform?: string;
}
export interface InfoCardProps {
  title: string;
  description: string | Array<descriptionListItem>;
  transparent: boolean;
  link?: link;
  type?: ActionElementType;
  handleElementAction?: () => void;
}
