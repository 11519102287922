import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDispatch } from "react-redux";

import WinLaptop from "../../assets/win-laptop.png";
import MacLaptop from "../../assets/mac-laptop.png";
import { useSnackBar } from "../../context/SnackBar";
import { api } from "../../api/base";
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import ChevronIcon from "../../assets/download-arrow.png";
import DensityLogo from "../../assets/Density-logo.png";
import Rec1 from "../../assets/rec1.png";
import WaitlistModal from "../../components/modals/Waitlist"; // Import the modal component
import macOSIcon from "../../assets/apple-black.png";
import { analyticsEvent } from "../../redux/actions/analytics";
import useDeviceInfo from "../../utils/hooks/useDeviceInfo";
import { DeviceType, OS } from "../../types/globals";
import useOnboarding from "../../utils/hooks/useOnboarding";

const FinalStep = (props: {
  clearData: () => Promise<void> | void;
  handleContinue: () => void;
  backToEmail: () => void;
  setAddedToWaitlist: (value: boolean) => void;
  addedToWaitlist: boolean;
}): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const [showDownloadInstructions, setShowDownloadInstructions] = useState(
    localStorage.getItem("retry_email") ||
    localStorage.getItem("userExists") ||
    searchParams.get("instructions") ||
    false,
  );
  const { showSnackBar } = useSnackBar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userEmail = localStorage.getItem("user_email");
  const isInvite = localStorage.getItem("referrer") !== "Density";
  const { isComingFromHardware } = useOnboarding();
  const [showModal, setShowModal] = useState(false); // State for showing the modal

  const deviceInfo = useDeviceInfo();

  useEffect(() => {
    console.log(
      searchParams.get("waitlist") === "true",
      !props.addedToWaitlist,
      !showModal,
    );
    if (
      searchParams.get("waitlist") === "true" &&
      !props.addedToWaitlist &&
      !showModal
    ) {
      setShowModal(true); // Show the modal when the waitlist parameter is true
    } else {
      setShowModal(false);
    }
  }, [searchParams.get("waitlist")]);

  const handleDownloadClick = (location: string) => {
    if (!showDownloadInstructions) {
      dispatch(
        analyticsEvent("EVENT", "Instruction Page Visited", {
          page: "Instruction Page",
        }),
      );
    }
    setShowDownloadInstructions(true);

    api
      .post("/auth/download", {
        email: userEmail,
        os: "universal",
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const submitToWaitlist = async (): Promise<void> => {
    setLoading(true);
    const decodedEmail = userEmail;
    const response = await api.post("waitlist", {
      email: decodedEmail,
      instagramLink: "",
      type: "windows",
      user_id: searchParams.get("user_id") || localStorage.getItem("user_id"),
    });
    if (response.status === 201) {
      dispatch(
        analyticsEvent("INPUT", "Waitlist: Email Entered", {
          page: "Watlist Email Page",
          email: decodedEmail,
          type: "windows",
        }),
      );
      props.setAddedToWaitlist(true);
      setLoading(false);
    } else {
      setLoading(false);
      showSnackBar(
        "Could not submit to waitlist. Please try again later.",
        "error",
        "center",
        2000,
        false,
        "auto",
        "95px",
      );
    }
  };

  const handleConfirm = () => {
    setShowModal(true);
    submitToWaitlist();
  };

  const handleCancel = () => {
    searchParams.delete("waitlist");
    setShowModal(false);
  };

  const changeEmail = () => {
    if (!localStorage.getItem("access_token")) {
      navigate("/login?email=change");
      return;
    }
    localStorage.setItem("retry_email", "true");
    props.backToEmail();
  };

  if (props.addedToWaitlist) {
    return (
      <div className="md:mt-[89px]">
        <div className="max-w-full p-[30px] text-center lg:max-w-[450px] lg:p-[0]">
          <img
            className="mx-auto mb-[35px] w-[200px] lg:w-[292px]"
            src={WinLaptop}
            alt=""
          />
          <p className="mb-[12px] font-[20px] leading-[24px] tracking-[0.15px] text-[#FCFCFC] lg:mb-[16px] lg:text-[20px]">
            You have been added to the Windows Waitlist.
          </p>
          <p className="font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC] lg:text-[20px]">
            We will email you as soon as it's released.
          </p>
          <p className="mt-[50px] font-normal leading-[24px] tracking-[0.15px] text-[#DCDEE4] lg:mt-[123px] lg:text-[16px]">
            Need help from a human DJ?{" "}
            <a className="text-[#99A0C5]" href="mailto:support@density.one">
              support@density.one
            </a>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="md:mt-[30px]">
      {showDownloadInstructions && deviceInfo.type === DeviceType.Desktop ? (
        <div className="p-[30px]">
          <div className="flex flex-col gap-[30px] text-center lg:flex-row">
            <div className="pt-[20px] lg:w-[300px]">
              <img
                className="mx-auto mb-[25px] h-[131px] w-[237px] object-contain"
                src={MacLaptop}
                alt=""
              />
              <p className="mb-[40px] text-[18px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                Open the Density <br />
                Desktop app
              </p>
              <a
                href="https://release.density.one/Density-Installer-Universal-Latest.dmg"
                download
              >
                <Button
                  fullWidth
                  variant={"contained"}
                  className="buttonWithIcon"
                  onClick={() => handleDownloadClick("Instruction page")}
                  sx={{ mb: "36px", height: "44px", fontSize: "13px" }}
                >
                  <img src={macOSIcon} className="iconIMG" alt="" />
                  <span> Download </span>
                </Button>
              </a>
            </div>
            <div className="flex h-[50px] items-center lg:h-[276px]">
              <img
                className="mx-auto h-[24px] w-[12px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="flex h-[200px] flex-col lg:h-[276px] lg:w-[300px]">
              <div className="mx-auto mt-auto flex h-[84px] w-[84px] items-center justify-center rounded-full border-[1px] border-[#62667C] lg:mb-[79px]">
                <span className="text-[34px] font-normal leading-[36px] text-white">
                  2
                </span>
              </div>
              <p className="mb-[16px] mt-auto text-[18px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC] lg:mb-[30px]">
                Log in using:
                <br />
                {userEmail}
              </p>
              <p className="text-[12px] font-medium leading-[18px] tracking-[0.1px] text-[#FCFCFC99]">
                Not your email?{" "}
                <span
                  className="cursor-pointer text-[#737BAE] underline"
                  onClick={changeEmail}
                >
                  Update it here.
                </span>
              </p>
            </div>
            <div className="flex h-[50px] items-center lg:h-[276px]">
              <img
                className="mx-auto h-[24px] w-[12px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="relative flex h-[150px] flex-col lg:h-[276px] lg:w-[300px]">
              <div className="mx-auto mt-auto flex h-[84px] w-[84px] items-center justify-center rounded-full border-[1px] border-[#62667C] lg:mb-[79px]">
                <span className="text-[34px] font-normal leading-[36px] text-white">
                  3
                </span>
              </div>
              <p className="mt-auto text-[18px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC] lg:mb-[30px]">
                Point and click to create a set in the DAW
              </p>
              <p className="invisible hidden cursor-none text-[12px] font-medium leading-[18px] tracking-[0.1px] text-[#FCFCFC99] lg:block">
                Not your email?{" "}
                <span
                  className="cursor-pointer text-[#737BAE] underline"
                  onClick={changeEmail}
                >
                  Update it here.
                </span>
              </p>
            </div>
            <div className="flex h-[50px] items-center lg:h-[276px]">
              <img
                className="mx-auto h-[24px] w-[12px] rotate-90 lg:rotate-0"
                src={ChevronIcon}
                alt=""
              />
            </div>
            <div className="pt-[20px] lg:w-[300px]">
              <img
                className="mx-auto mb-[50px] h-[109px] w-[120px] object-contain"
                src={Rec1}
                alt=""
              />
              <p className="mb-[40px] text-[18px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                Connect your laptop to your decks to record a set.
                <br />
                <br />
                <br />
                Upgrade to the Density Hardware for club environments. Available
                in the app.
              </p>
            </div>
          </div>
          <div className="flex flex-col justify-center gap-[30px] text-center lg:flex-row">
            <div className="pt-[20px]">
              <div className="mt-[70px]">
                <p className="mb-[16px] text-center text-[16px] font-normal leading-[24px] tracking-[0.15px] text-[#62667C]">
                  Windows Coming Soon!
                </p>
                <Button
                  fullWidth
                  variant={"contained"}
                  onClick={() => setShowModal(true)} // Show modal when the button is clicked
                  sx={{
                    mb: "25px",
                    height: "44px",
                    fontSize: "11px",
                    background: "#FCFCFC1A",
                    color: "#FCFCFC99",
                    "&:hover": { background: "#FCFCFC1A", color: "#FCFCFC99" },
                  }}
                >
                  Add to waitlist
                </Button>
                <p className="text-[16px] font-normal leading-[24px] tracking-[0.15px] text-[#DCDEE4]">
                  Need help from a human DJ?{" "}
                  <a
                    className="text-[#99A0C5]"
                    href="mailto:support@density.one"
                  >
                    support@density.one
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      )
        : (
          <div>
            {(deviceInfo.type === DeviceType.Desktop && deviceInfo.os === OS.MacOS) && (
              <div
                className=" max-w-[330px] lg:block"
              // style={{ display: isMobileOrTablet ? "none" : "block" }}
              >
                {isInvite && (
                  <div className="relative z-10 mx-auto mb-[30px] mt-[-53px] h-[120px] w-[120px] overflow-hidden rounded-full">
                    <img
                      className={`${localStorage.getItem("imgUrl") === DensityLogo
                        ? "object-contain p-2.5"
                        : "object-cover p-0"
                        }`}
                      alt={localStorage.getItem("referrer") || ""}
                      src={localStorage.getItem("imgUrl") || ""}
                    />
                  </div>
                )}
                <p className="mb-[32px] px-[15] text-center text-[20px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                  {/* {isComingFromHardware && "Your order is on the way and you're subscribed to premium!"} */}
                  {isComingFromHardware && "Your order is on the way!"}


                  {isInvite && `from ${localStorage.getItem("referrer")}`} <br />{" "}
                  {!isComingFromHardware && "Your invite gives you access to the "}
                  {!isComingFromHardware && "Density Desktop app"}
                </p>
                <a
                  href="https://release.density.one/Density-Installer-Universal-Latest.dmg"
                  download
                >
                  <Button
                    fullWidth
                    variant={"contained"}
                    className="buttonWithIcon"
                    onClick={() =>
                      handleDownloadClick("Registration Step - Download")
                    }
                    sx={{ mb: "36px", height: "44px", fontSize: "13px" }}
                  >
                    <img src={macOSIcon} className="iconIMG" alt="" />
                    <span> Download </span>
                  </Button>
                </a>
                <img className="mb-[32px]" src={MacLaptop} alt="" />
                {!isComingFromHardware &&
                  <>
                    <p className="mb-[65px] text-center text-[20px] leading-[24px] tracking-[0.15px] text-[#717274]">
                      Hardware can be purchased in the app
                    </p>
                    <p className="mb-[16px] text-center text-[16px] font-normal leading-[24px] tracking-[0.15px] text-[#62667C]">
                      Windows Coming Soon!
                    </p>
                    <Button
                      fullWidth
                      variant={"contained"}
                      onClick={() => setShowModal(true)} // Show modal when the button is clicked
                      sx={{
                        mb: "36px",
                        height: "44px",
                        fontSize: "11px",
                        background: "#FCFCFC1A",
                        color: "#FCFCFC99",
                        "&:hover": { background: "#FCFCFC1A", color: "#FCFCFC99" },
                      }}
                    >
                      Add to waitlist
                    </Button>
                  </>
                }
              </div>
            )}

            {(deviceInfo.type !== DeviceType.Desktop || deviceInfo.os !== OS.MacOS) && (
              <div
                className="max-w-[302px] text-center"
              // style={{ display: isMobileOrTablet ? "block" : "none" }}
              >
                <p className="mb-[12px] px-[15] text-center text-[20px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                  Your download link was sent to <br /> {userEmail}
                </p>
                <p className="mb-[56px] text-[12px] font-medium leading-[18px] tracking-[0.1px] text-[#FCFCFC99]">
                  Not your email?{" "}
                  <span
                    className="cursor-pointer text-[#737BAE] underline"
                    onClick={changeEmail}
                  >
                    Update it here.
                  </span>
                </p>
                <p className="mb-[24px] text-[14px] font-semibold leading-[24px] tracking-[0.1px] text-[#FCFCFC99]">
                  Install the Density Desktop app on
                  <br /> your computer
                </p>
                <img className="mb-[45px]" src={MacLaptop} alt="" />
              </div>
            )}

          </div>
        )}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backdropFilter: "blur(2px)",
        }}
        open={loading}
      >
        <Box
          width={"100px"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          <CircularProgress
            sx={{
              marginBottom: "20px",
            }}
            color="inherit"
          />
          Loading ...
        </Box>
      </Backdrop>
      <WaitlistModal
        show={showModal}
        title="Do you want to join Windows waitlist?"
        message="This will stop your sign-up process. We will email you as soon as Density is released for Windows."
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </div>
  );
};

export default FinalStep;
