import { Box, Typography, Modal, } from "@mui/material";

import { StyledAccountContent } from "../../components/micro/micro.styled";

import CloseModalIcon from "../../assets/CloseModalIcon.svg";
import CancelPlanSuccess from "../../assets/CancelPlanSuccess.svg";

interface PaymentDetailsChangedModalProps {
	open: boolean;
	handleClose: () => void;
	price: string;
}

const PaymentDetailsChangedModal = ({
	open,
	handleClose,
	price,
}: PaymentDetailsChangedModalProps): JSX.Element => {
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="modal-modal-title"
			disableAutoFocus={true}
			aria-describedby="modal-modal-description"
			sx={{
				backdropFilter: "blur(3px)",
				minWidth: "350px",
			}}
		>
			<Box
				gap={"20px"}
				justifyContent={"center"}
				alignItems={"center"}
				flexDirection={"column"}
				display={"flex"}
				height={"100%"}
				width={"100%"}
			>
				<StyledAccountContent sx={{ gap: "16px", width: '430px' }}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"flex-end"}
					>
						<button onClick={handleClose} style={{ border: 'none', background: 'transparent', cursor: 'pointer', padding: 0, height: '20px', width: '20px' }}>
							<img
								style={{
									maxWidth: "20px",
									objectFit: "contain",
									cursor: "pointer",
								}}
								src={CloseModalIcon}
								alt="close modal"
							/>
						</button>
					</Box>
					<Box
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"center"}

					>
						<img
							src={CancelPlanSuccess}
							style={{
								height: "80px",
								width: "80px",
								objectFit: "contain",
								marginBottom: "20px",
							}}
							alt="success"
						/>
						<Typography
							variant={"h2"}
							fontWeight={500}
							color={"#F3F3F5"}
							fontSize={20}
							lineHeight={"24px"}
							letterSpacing={"0.15px"}
							maxWidth={"250px"}
							textAlign={"center"}
						>
							You've changed your payment details.
						</Typography>
						<Typography
							my={"16px"}
							textAlign={"center"}
							variant={"body1"}
							color={"rgba(252, 252, 252, 0.6)"}
							letterSpacing={"0.4px"}
							fontSize={12}
							lineHeight={"18px"}
							fontWeight={500}
							maxWidth={"350px"}
						>
							{ price ? `${price} per month. An update to your current subscription will be reflected on your next billing date.` : 'Your card has been successfully added.'}
						</Typography>
					</Box>
				</StyledAccountContent>
			</Box>
		</Modal>
	);
};

export default PaymentDetailsChangedModal;
