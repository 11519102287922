export const types = {
  APPLICATION_START: `APPLICATION_START`,
  SESSION_CREATED: "SESSION_CREATED",
  PAYMENT_DETAILS_CHANGED: "PAYMENT_DETAILS_CHANGED",
	LOGIN_MODAL_OPEN: "LOGIN_MODAL_OPEN",
	SET_USER_DATA_EMAIL: "SET_USER_DATA_EMAIL",
	SET_FORGOT_PASSWORD_MODAL_OPEN: "SET_FORGOT_PASSWORD_MODAL_OPEN",
};

export const appStart = (userId: string) => ({
  type: types.APPLICATION_START,
  payload: {
    userId
  },
});

export const sessionCreated = (sessionId: string) => ({
  type: types.SESSION_CREATED,
  payload: {
    sessionId
  },
})

export const paymentDetailsChanged = (paymentDetailsChanged: boolean) => ({
  type: types.PAYMENT_DETAILS_CHANGED,
  payload: {
    paymentDetailsChanged
  },
})

export const setLoginModalOpen = (loginModalOpen: boolean) => ({
	type: types.LOGIN_MODAL_OPEN,
	payload: {
		loginModalOpen
	},
})

export const setUserDataEmail = (email: string) => ({
	type: types.SET_USER_DATA_EMAIL,
	payload: {
		email
	},
})

export const setForgotPasswordModalOpen = (forgotPasswordModalOpen: boolean) => ({
	type: types.SET_FORGOT_PASSWORD_MODAL_OPEN,
	payload: {
		forgotPasswordModalOpen
	},
})
