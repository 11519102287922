import { Typography } from "@mui/material";
import Lottie from "lottie-react";

import {
  StyledDensityFeaturesContainer,
  StyledDensityFeaturesWrapper,
  StyledMasterSetItem,
} from "../../pages/LandingPage/landing.styled";

import LUFS from "../../assets/Thirteenth block desktop.png";
import Limit from "../../assets/Fourteenth block desktop.png";

import "./masterSet.css";

type MasterSetProps = {
  width: number;
};

const MasterSet = ({ width }: MasterSetProps) => {
  return (
    <StyledDensityFeaturesContainer>
      <StyledDensityFeaturesWrapper>
        <StyledMasterSetItem
          sx={{
            paddingTop: {
              xs: "60px",
              md: "0px",
            },
          }}
        >
          <div className="lottie-animation">
            {/* <Lottie animationData={LoopsAnimation} loop={true} /> */}
            <img src={LUFS} alt="" />
          </div>
          <Typography variant="subtitle2">
            Keep the volume consistent
          </Typography>
          <p>
            A LUFS meter visualizes if the loudness is consistent over time. A
            consistent volume keep listeners coming back.
          </p>
        </StyledMasterSetItem>
        <StyledMasterSetItem>
          <Typography variant="subtitle2" className="master-padding-top">
            Limiting
          </Typography>
          <p
            style={{
              maxWidth: "400px",
            }}
          >
            A mastering grade limiter optimizes the set for the SoundCloud.
          </p>
          <img
            style={{
              objectFit: "contain",
              width: width > 769 ? "445px" : "100%",
              height: width > 769 ? "360px" : "auto",
              margin: "0 auto",
              padding: width > 900 ? "0px" : "30px",
              marginTop: width > 769 ? "42px" : "0px",
            }}
            src={Limit}
            alt=""
          />
        </StyledMasterSetItem>
      </StyledDensityFeaturesWrapper>
    </StyledDensityFeaturesContainer>
  );
};

export default MasterSet;
