import React, { useEffect } from 'react';
import { StyledSidebarContainer } from './Sidebar.styled';
import { Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface SidebarProps {
  setOpenModal?: (open: boolean) => void;
}


const Sidebar = (props: SidebarProps): JSX.Element => {
  const { setOpenModal } = props;
  const navigate = useNavigate();
  return (
    <StyledSidebarContainer>
      <Typography variant={'h4'}>
        Density
      </Typography>
      <Button
        variant={'contained'}
        onClick={() => navigate('/plans')}
      >
        Subscribe
      </Button>
    </StyledSidebarContainer>
  );
}

export default Sidebar;
