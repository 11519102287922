import { Box, Typography } from "@mui/material";
import { StyledAuthButton } from "../micro/micro.styled";

import LaptopTrialMobile from "../../assets/trial-mobile.png";
import LaptopTrial from "../../assets/trial-image.png";
import { StyledFreeTrialWrapper } from "../../pages/LandingPage/landing.styled";
import { useNavigate } from "react-router-dom";

type StartYourFreeTrialProps = {
  width: number;
  getDensity?: () => void;
};

const StartYourFreeTrial = ({ width, getDensity }: StartYourFreeTrialProps) => {
  const navigate = useNavigate();
  return (
    <StyledFreeTrialWrapper>
      <Box>
        <Typography
          variant="subtitle2"
          sx={{
            background:
              "linear-gradient(189.02deg, #FFFFFF 9.04%, rgba(255, 255, 255, 0) 177.73%)",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
            fontSize: {
              xs: "30px",
              md: "45px",
            },
            paddingTop: {
              xs: "10px",
              md: "0px",
            },
          }}
        >
          Give it a try
        </Typography>
        <StyledAuthButton
          variant="contained"
          color="primary"
          onClick={getDensity || (() => navigate("/login"))}
          sx={{
            width: 220,
            height: {
              xs: 36,
              md: 44,
            },
            backgroundColor: "#c1c5dd",
            marginTop: "24px !important",
            zIndex: 1,
            mx: "auto",
            marginBottom: {
              xs: "40px !important",
              md: "70px",
            },
          }}
        >
          Download Density
        </StyledAuthButton>
      </Box>
      <img src={width > 769 ? LaptopTrial : LaptopTrialMobile} alt="" />
    </StyledFreeTrialWrapper>
  );
};

export default StartYourFreeTrial;
