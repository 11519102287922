import React from "react";
import { useNavigate } from "react-router-dom";

import { Typography, InputLabel, Box } from "@mui/material";
import { WaitlistProps } from "../../types/waitlist";
import {
  StyledAuthInputs,
  StyledAuthInputFields,
  StyledAuthButton,
} from "../micro/micro.styled";

import CloseCircle from "../../assets/close-circle.svg";
import Scratching from "../../assets/scratching.png";
import { useDispatch } from "react-redux";
import { setLoginModalOpen } from "../../redux/actions/app";

const EmailStep = (props: WaitlistProps): JSX.Element => {
  const {
    onChangeHandler,
    userData,
    nextUserFunction,
    formError,
    userExists,
    onNextKeyPress,
  } = props;

	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleUserExists = () => {
		navigate('/');
		dispatch(setLoginModalOpen(true));
	};

  return (
    <StyledAuthInputs>
      <img
        style={{
          width: "200px",
          objectFit: "contain",
          margin: "0 auto",
          height: "150px",
        }}
        src={Scratching}
        alt=""
      />
      <Typography
        variant={"h2"}
        mx={"auto"}
        align={"center"}
        maxWidth={"260px"}
        mb={"16px"}
        mt={'24px'}
      >
        Density is in private Beta and currently is available to Pro DJs.
      </Typography>
      <Typography
        fontSize={"12px"}
        fontWeight={500}
        textAlign={"center"}
        variant={"body1"}
        color={"rgba(253, 253, 253, 0.6)"}
        maxWidth={275}
        mx={"auto"}
        lineHeight={"18px"}
        letterSpacing={"0.1px"}
      >
        If that's you, apply here for access
      </Typography>
      <Box display={"flex"} flexDirection={"column"} position={"relative"} mt={"20px"}>
        <InputLabel
          sx={{
            top: "-10px",
          }}
          htmlFor="email"
        >
          Email
        </InputLabel>
        <StyledAuthInputFields
          value={userData.email}
          onChange={onChangeHandler}
          onKeyDown={onNextKeyPress}
          type={"email"}
          name={"email"}
          error={formError !== ""}
          sx={{
            input: {
              paddingRight: "48px",
            },
          }}
        />
        {formError && (
          <Typography fontSize={12} color={"error"} variant={"body1"}>
            {formError}
            {userExists && <span style={{ cursor: 'pointer' }} onClick={handleUserExists}> Sign in.</span>}
          </Typography>
        )}
        {formError !== "" && (
          <img
            style={{
              height: "16px",
              width: "16px",
              objectFit: "contain",
              position: "absolute",
              bottom: "36px",
              right: "16px",
            }}
            src={CloseCircle}
            alt=""
          />
        )}
      </Box>
      <StyledAuthButton
        onClick={() => nextUserFunction()}
        variant={"contained"}
        disabled={userData.email === "" || formError !== ""}
      >
        Save and continue
      </StyledAuthButton>
    </StyledAuthInputs>
  );
};

export default EmailStep;
