import { InfoPanelProps } from "./types";

function InfoPanel({
  data,
  children,
}: {
  data: InfoPanelProps;
  children: React.ReactNode;
}) {
  const { title, subtitle } = data;

  return (
    <div className="flex flex-col items-center justify-center text-center text-primary-purple">
      <div className="font-['PPRightGrotesk-Bold'] text-[34px] font-black leading-[38.85px] mb-[4.6875rem]">
        {title}
      </div>
      <div className="md:py-8 md:pl-8">{children}</div>
      <div className="max-w-[30ch] font-['Inter'] text-[12px] font-medium leading-[18px] tracking-[0.1px] !text-base-gray ">
        {subtitle}
      </div>
    </div>
  );
}

export default InfoPanel;
