import React, { useContext, useState } from 'react';
import { UserProps } from '../types/auth';

type UserContextActions = {
  plan: {
    planId: string,
    planName: string,
  };
  user: UserProps,
  setPlan: ({ planId, planName }: { planId: string, planName: string }) => void;
  setUser: (user: UserProps) => void;
};

interface UserContextProviderActions {
  children: React.ReactNode;
}

const UserContext = React.createContext({ } as UserContextActions);

const UserProvider: React.FC<UserContextProviderActions>  = ({ children }: any) => {
  const [plan, setPlan] = useState<{ planId: string, planName: string }>({
    planId: '',
    planName: '',
  });
  const [user, setUser] = useState<UserProps>({} as UserProps)
  return (
    <UserContext.Provider value={{ setPlan, plan, user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = (): UserContextActions => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error('useUserContext must be used within an UserProvider');
  }

  return context;
};

export { UserProvider, useUserContext };