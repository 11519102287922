import { Box, Typography } from "@mui/material";
import {
  StyledHeroWithText,
  StyledHeroWithTextContent,
} from "../../pages/LandingPage/landing.styled";

type HeroWithTextProps = {
  bgImage: string;
  imageGradient: string;
  textGradient: string;
  title: string;
  subtitle: string;
  text: string;
  direction: "left" | "right";
};

const HeroWithText = ({
  bgImage,
  imageGradient,
  textGradient,
  title,
  subtitle,
  text,
  direction,
}: HeroWithTextProps) => {
  return (
    <StyledHeroWithText
      sx={{
        backgroundImage: `url("${bgImage}")`,

        "&::before": {
          background: imageGradient,
        },
      }}
    >
      <StyledHeroWithTextContent direction={direction}>
        <p className="hero-text-subtitle">{subtitle}</p>
        <Typography
          variant="subtitle2"
          sx={{
            background: { xs: "#c1c5dd", md: textGradient },
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          }}
        >
          {title}
        </Typography>
        <p className="hero-text">{text}</p>
      </StyledHeroWithTextContent>
    </StyledHeroWithText>
  );
};

export default HeroWithText;
