import React from "react";
import { Typography, Modal, CircularProgress, Box } from "@mui/material";

import { StyledModalContent } from "../micro/micro.styled";

import DensityLogoDark from "../../assets/Density-logo-dark.svg";
import DensityLogo from "../../assets/Density-logo.svg";
interface CheckoutModalProps {
  open: boolean;
  handleClose: () => void;
  url: string;
  error: string | null;
  isAccountPage?: boolean;
}

const CheckoutModal = (props: CheckoutModalProps) => {
  const { open = false, handleClose, url, error, isAccountPage } = props;

  return (
    <Modal open={open} onClose={handleClose}>
      <StyledModalContent
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        sx={{
          backgroundColor: isAccountPage ? "#151519" : "#21232B",
        }}
      >
        {url ? (
          <>
            <img
              src={isAccountPage ? DensityLogoDark : DensityLogo}
              alt="Density"
              style={{
                maxWidth: 108,
                height: 20,
                margin: "32px 0",
                objectFit: "contain",
              }}
            />
            <Box>
              <Typography textAlign={'center'} fontSize={20} lineHeight={'24px'} variant={'h2'} marginBottom={'8px'}>
                Try Density free for 14 days
              </Typography>
              <Typography
                textAlign={'center'}
                variant={'h2'}
                fontSize={16}
                marginBottom={'16px'}
                fontWeight={400}
              >
                $15/month after that.
              </Typography>
              <Typography textAlign={'center'} fontSize={12} color={'rgba(252, 252, 252, 0.60)'} variant="body1" marginBottom={'34px'}>
                We&apos;ll email you a reminder before we charge.
              </Typography>
            </Box>
            <iframe
              style={{
                border: "none",
              }}
              src={url}
              title={"chargebee-checkout"}
            />
          </>
        ) : (
          <CircularProgress />
        )}
        {error && <Typography>{error}</Typography>}
      </StyledModalContent>
    </Modal>
  );
};

export default CheckoutModal;
