import React, { useEffect } from "react";
import { AuthProps } from "../../types/auth";
import Lottie from "lottie-react";
import DjAnimation from "../../assets/lottie/dj-animation.json";
import { Box, LinearProgress, Typography, Button } from "@mui/material";
import { StyledAuthButton, StyledAuthInputs } from "../micro/micro.styled";
import DensityLogo from "../../assets/Density-logo.svg";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";

const FirstStep = (props: AuthProps) => {
  const dispatch = useDispatch();
  const totalNumberOfInvites = props.referrer?.invites!;
  const numberOfInvitesLeft = props.referrer?.invitesLeft!;
  const percentage =
    ((totalNumberOfInvites - numberOfInvitesLeft) / totalNumberOfInvites) * 100;
  useEffect(() => {
    localStorage.setItem("onboarding_in_progress", "true");
  }, []);
  useEffect(() => {
    dispatch(
      analyticsEvent("PAGE_VISIT", "IG-1.1: 1st Page Visited", {
        page: "1st Page",
        refId: props.referrer?.referrer ? props.referrer?.referrer : "",
      }),
    );
  }, []);
  return (
    <StyledAuthInputs>
      <Box width={"100%"}>
        <Box position={"relative"} margin={"-50px auto 0 auto"}>
          <img
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "50%",
              height: "120px",
              width: "120px",
              zIndex: 1,
              border: "10px solid white",
              objectFit:
                props.referrer?.imgUrl === DensityLogo ? "contain" : "cover",
              padding: props.referrer?.imgUrl === DensityLogo ? "10px" : "0px",
            }}
            alt={props.referrer?.referrer}
            src={props.referrer?.imgUrl}
          />
          <Lottie animationData={DjAnimation} loop={true} />
        </Box>
        <Box
          sx={{
            maxWidth: "400px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h5"}
            sx={{ color: "#fdfdfd", fontSize: "20px" }}
            mt={0}
            pb={1}
            textAlign={"center"}
          >
            {`${props.referrer?.referrer === "Density" ? "Private Beta" : props.referrer?.referrer}`}
          </Typography>
          <Typography
            textAlign={"center"}
            variant={"body1"}
            sx={{ color: "rgba(255, 255, 255, 0.6)" }}
            mt={0}
            pb={3}
          >
            {`${props.referrer?.referrer === "Density" ? "" : "has"} ${numberOfInvitesLeft} invites to Density left`}
          </Typography>
          <LinearProgress
            variant="determinate"
            sx={{
              borderRadius: "4px",
              height: "7px",
              backgroundColor: "rgba(217, 217, 217, 0.10)",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "rgba(217, 217, 217, 0.30)",
              },
            }}
            value={percentage}
          />
        </Box>
      </Box>
      <StyledAuthButton
        sx={{
          marginTop: {
            sm: "45px",
          },
        }}
        onClick={props.nextUserFunction}
        variant={"contained"}
      >
        Get access
      </StyledAuthButton>
    </StyledAuthInputs>
  );
};

export default FirstStep;
