import React, { useEffect, useState } from 'react';
import LandingNavbar from '../../components/LandingNavbar';
import useWindowDimensions from '../../utils/hooks/windowDimensionsHook';
import { Typography } from '@mui/material';
import { StyledAuthButton } from '../../components/micro/micro.styled';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';

const NotFoundPage = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [visible, setVisible] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      if (currentScrollPos > prevScrollPos) {
        setVisible(true);
      } else {
        setVisible(false);
      }

      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener('scroll', handleScroll, true);
  }, []);

  return (
    <>
      {localStorage.getItem('access_token') ? (
        <Header />
      ) : (
        <LandingNavbar ww={width} visible={visible} />
      )}
      <div
        style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', height: '100vh' }}
      >
        <div>
          <Typography
            className="density-hero-heading"
            variant="h1"
            component="h1"
            sx={{
              fontSize: {
                md: '300px',
                xs: '125px',
              },
              lineHeight: {
                md: '342.77px',
                xs: '142.82px ',
              },
              background: {
                md: 'linear-gradient(182.08deg, #FFFFFF -35.04%, rgba(112, 117, 196, 0.25) 164.5%)',
                xs: 'linear-gradient(192.02deg, #FFFFFF -12.23%, rgba(112, 117, 196, 0.1) 125.86%)',
              },
              '-webkit-background-clip': {
                md: 'text',
                xs: 'text',
              },
              '-webkit-text-fill-color': {
                md: 'transparent',
                xs: 'transparent',
              },
            }}
          >
            404{' '}
          </Typography>
          <Typography
            sx={{
              fontSize: {
                md: '14px',
                xs: '12px',
              },
              lineHeight: {
                md: '24px',
                xs: '20px',
              },
              color: 'rgba(158, 161, 178, 1)',
              fontFamily: 'Inter',
              textAlign: 'center',
            }}
          >
            Unz unz unz… We couldn't find the page you were<br></br> looking for. Go back to
            homepage and try again.
          </Typography>
          <StyledAuthButton
            variant="contained"
            color="primary"
            className="density-hero-button"
            onClick={() => navigate('/')}
            sx={{
              display: 'flex',
              width: {
                md: 200,
                xs: 250,
              },
              padding: {
                xs: '10px 16px',
                md: '15px',
              },
              backgroundColor: '#c1c5dd',
              margin: 'auto',
              mt: '50px',
              mb: '0px',
            }}
          >
            GO TO HOMEPAGE
          </StyledAuthButton>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
