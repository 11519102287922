import { Navigate, useSearchParams } from "react-router-dom";

const ProtectedRoute = ({ children }: any) => {
  const [searchParams] = useSearchParams();
  if (searchParams.get("access_token")) {
    return children;
  }

  if (localStorage.getItem("access_token")) {
    return children;
  }

  // url with search params
  const url = `/${window.location.search}`;
  return <Navigate to={url} />;
};

export default ProtectedRoute;
