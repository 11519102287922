import { Box, Typography } from "@mui/material";
import Lottie from 'lottie-react';
import EditImage from "../../assets/edit-image.png";
import MoveTrackImage from "../../assets/moving-track-image.png";
import MoveTrackAnimation from '../../assets/lottie/Page1++.json';
import {
  StyledEditYourSetFirstItem,
  StyledEditYourSetSecondItem,
  StyledEditYourSetWrapper,
} from "../../pages/LandingPage/landing.styled";

type EditYourSetProps = {
  lightLeftRef: React.RefObject<HTMLDivElement>;
  lightRightRef: React.RefObject<HTMLDivElement>;
};

const EditYourSet = ({ lightLeftRef, lightRightRef }: EditYourSetProps) => {
  return (
    <StyledEditYourSetWrapper ref={lightLeftRef}>
      <StyledEditYourSetFirstItem>
        <p className="edit-set-addon">DESKTOP SOFTWARE</p>
        <Typography variant="subtitle2">
          Edit your set after in the Density app
        </Typography>
        <p>
          When you’re done recording, you’ll see a recreation of the entire set
          where you can non-destructively edit transitions. Density is designed
          from the ground up for DJ mixes, making the workflow fast and
          intuitive.
        </p>
        <img src={EditImage} alt="" />
      </StyledEditYourSetFirstItem>
      <StyledEditYourSetSecondItem>
        {/* <img src={MoveTrackImage} alt="" /> */}
        <Lottie className="move-track-animation" animationData={MoveTrackAnimation} loop={true} />
        <p className="edit-set-addon">DESKTOP SOFTWARE</p>
        <Typography variant="subtitle2">
          Moving a track preserves the downstream transitions
        </Typography>
        <p>
          Any change you make to the set, will preserve all of your later
          transitions. This works for moving, reordering and removing tracks.
        </p>
      </StyledEditYourSetSecondItem>
    </StyledEditYourSetWrapper>
  );
};

export default EditYourSet;
