import { useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import MacLaptop from "../../assets/mac-laptop.png";
import { analyticsEvent } from "../../redux/actions/analytics";
import CoverImage from "../../assets/new_cover_image.webp";
import PlayIcon from "../../assets/Pause Controls.svg";
import DensityLogo from "../../assets/Density app logo.svg";

const Reminder = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [startTime, setStartTime] = useState<number | null>(null);
  const email = decodeURIComponent(searchParams.get("email") || "").replaceAll(
    " ",
    "+",
  );

  const handleExplainerOpen = () => {
    const currentTime = new Date().getTime();
    setStartTime(currentTime);
    dispatch(
      analyticsEvent("CLICK", "Started Explainer Video", {
        page: "Landing Page",
      }),
    );
    setOpen(true);
  };

  const handleExplainerClose = () => {
    const currentTime = new Date().getTime();
    let timeWatched = null;
    if (startTime !== null) {
      const timeDiff = currentTime - startTime;
      const minutes = Math.floor(timeDiff / 60000);
      const seconds = Math.floor((timeDiff % 60000) / 1000);
      timeWatched = `${minutes}m ${seconds}s`;
    }
    dispatch(
      analyticsEvent("CLICK", "Closed Explainer Video", {
        page: "Landing Page",
        timeWatched: timeWatched,
      }),
    );
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
    return () => {
      document.body.style.overflowY = "scroll";
    };
  }, [isOpen]);

  useEffect(() => {
    if (!searchParams.get("sent")) {
      // add sent search param
      dispatch({
        type: "SET_REMINDER",
        payload: {
          user_id: searchParams.get("user_id"),
          reminderType: searchParams.get("reminderType"),
        },
      });
      navigate(`${window.location.search}&sent=true`);
    }
  }, []);

  const formatReminderType = (reminderType: string) => {
    if (reminderType === "thisWeekend") {
      return "this Weekend";
    }
    return reminderType;
  };

  const rootElement = document.getElementById("root");

  // Check if the element exists
  if (rootElement) {
    // Add the class
    rootElement.classList.add("bg-darkish");
  }

  return (
    <>
      <div className="bg-darkish flex flex-col items-center justify-center p-[30px] md:mt-[0px]">
        {/* LOGO */}
        <div style={{ cursor: "pointer" }} onClick={() => navigate("/")}>
          <img
            src={DensityLogo}
            alt={"Density"}
            className="!m-0 !max-h-[36px] w-[117px] cursor-pointer"
          />
        </div>
        {/* BODY CONTENT */}
        <div className="bg-darkish flex flex-col items-center justify-center p-[30px]">
          <div className="flex flex-col items-center justify-center gap-[10px] text-center lg:flex-row lg:gap-[100px]">
            <div className="pt-[20px] text-center">
              <img
                className="mx-auto mb-[25px] h-[170px] w-[312px] object-contain"
                src={MacLaptop}
                alt=""
              />
              <p className="text-[20px] font-medium leading-[24px] tracking-[0.15px] text-[#FCFCFC]">
                We will email you a reminder to <br />
                download Density{" "}
                {formatReminderType(searchParams.get("reminderType") || "")}
              </p>
              <div className="relative mx-auto mt-[36px] flex w-full max-w-[500px] items-center justify-center overflow-hidden rounded-[10px] md:h-[300px] md:w-[calc(100%-80px)] lg:rounded-[30px] ">
                <ModalVideo
                  channel="youtube"
                  isOpen={isOpen}
                  videoId="Ix7_3nIACpU"
                  youtube={{
                    autoplay: 1,
                    showinfo: 0,
                    controls: 0,
                  }}
                  onClose={handleExplainerClose}
                />
                <img className="img-full-width" src={CoverImage} alt="" />
                <button
                  className="absolute left-1/2 top-1/2 z-10 h-[44px] w-[44px] -translate-x-1/2 -translate-y-1/2 transform focus:outline-none lg:h-[44px] lg:w-[44px]"
                  onClick={handleExplainerOpen}
                  type="button"
                  name="play-button"
                >
                  <img role="presentation" src={PlayIcon} alt="" />
                </button>
                <span className="gettigStarted lg:text-1xl absolute bottom-10 left-1/2 -translate-x-1/2 transform font-PPRightGrotesk-WideMedium text-sm leading-7 md:mt-[8px] lg:mx-0  xl:mt-[45%]">
                  Getting started with Density
                </span>
              </div>
              <div>
                <p className="text-[16px] font-normal leading-[24px] tracking-[0.15px] text-[#DCDEE4]">
                  Need help from a human DJ?{" "}
                  <a
                    className="text-[#99A0C5]"
                    href="mailto:support@density.one"
                  >
                    support@density.one
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reminder;
