import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputLabel,
  Modal,
  Divider,
  IconButton,
  InputAdornment,
} from "@mui/material";

import { useUserContext } from "../../context/UserContext";
import {
  StyledAccountContent,
  StyledAuthButton,
  StyledAuthInputFields,
} from "../../components/micro/micro.styled";

import CloseModalIcon from "../../assets/CloseModalIcon.svg";
import { api } from "../../api/base";
import { useSnackBar } from "../../context/SnackBar";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { analyticsEvent } from "../../redux/actions/analytics";

interface ChangePasswordModalProps {
  open: boolean;
  handleClose: () => void;
}

const ChangePasswordModal = ({
  open,
  handleClose,
}: ChangePasswordModalProps): JSX.Element => {
  const { showSnackBar } = useSnackBar();
  const {
    user: { email },
  } = useUserContext();
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState<string>("");
  const [oldPasswordError, setOldPasswordError] = useState<string>("");
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);

  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordError, setNewPasswordError] = useState<string>("");
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);

  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const handleClickShowPassword = (field: string) => {
    switch (field) {
      case "oldPassword":
        setShowOldPassword((show) => !show);
        break;
      case "newPassword":
        setShowNewPassword((show) => !show);
        break;
      case "confirmPassword":
        setShowConfirmPassword((show) => !show);
        break;
      default:
        break;
    }
  };

  const validatePassword = (value: string, field: string): void => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[!@#$&.,*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;
    if (!passwordRegex.test(value)) {
      switch (field) {
        case "oldPassword":
          setOldPasswordError(
            "Password must contain at least 8 characters, a special character, a number, and a capital letter.",
          );
          break;
        case "newPassword":
          setNewPasswordError(
            "Password must contain at least 8 characters, a special character, a number, and a capital letter.",
          );
          break;
        case "confirmPassword":
          setConfirmPasswordError(
            "Password must contain at least 8 characters, a special character, a number, and a capital letter.",
          );
          break;
        default:
          break;
      }
    } else {
      switch (field) {
        case "oldPassword":
          setOldPasswordError("");
          break;
        case "newPassword":
          setNewPasswordError("");
          break;
        case "confirmPassword":
          setConfirmPasswordError("");
          break;
        default:
          break;
      }
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    validatePassword(e.target.value, e.target.name);
    switch (e.target.name) {
      case "oldPassword":
        setOldPassword(e.target.value);
        break;
      case "newPassword":
        setNewPassword(e.target.value);
        break;
      case "confirmPassword":
        setConfirmPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  const submitHandler = async () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      if (!oldPassword) {
        setOldPasswordError("This is a required field.");
      }
      if (!newPassword) {
        setNewPasswordError("This is a required field.");
      }
      if (!confirmPassword) {
        setConfirmPasswordError("This is a required field.");
      }
      return;
    }
    if (newPassword === oldPassword) {
      setNewPasswordError(
        "New password must be different from the old password.",
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      setNewPasswordError("Passwords don't match.");
      setConfirmPasswordError("Passwords don't match.");
      return;
    }
    setOldPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");

    try {
      const response = await api.post("auth/set-new-password", {
        email,
        password: oldPassword,
        newPassword,
      });
      if (response.status === 200) {
        handleClose();
        showSnackBar(
          "Password changed successfully",
          "success",
          "center",
          2000,
          false,
          "auto",
        );
        dispatch(
          analyticsEvent("EVENT", "Profile: Changed password", {
            updatedAt: new Date().toISOString(),
          }),
        );
      }
    } catch (err: any) {
      console.log(err);
      handleClose();
      showSnackBar(
        err?.response.data.msg || "Password change failed. Please try again.",
        "error",
        "center",
        2000,
        false,
        "auto",
      );
    }
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!open) {
      setOldPassword("");
      setOldPasswordError("");
      setNewPassword("");
      setNewPasswordError("");
      setConfirmPassword("");
      setConfirmPasswordError("");
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      disableAutoFocus={true}
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(3px)",
        "& .MuiModal-backdrop": {
          backgroundColor: "rgba(0, 0, 0, 0.75)",
        },
      }}
    >
      <Box
        gap={"20px"}
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={"column"}
        display={"flex"}
        height={"100%"}
        maxWidth={"420px"}
        width={"100%"}
        mx={"auto"}
      >
        <StyledAccountContent
          sx={{
            width: "100%",
            gap: "16px",
            backgroundColor: "#141519",
            overflowY: "auto",
          }}
        >
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography variant={"h2"}>Change your password</Typography>
            <button
              onClick={handleClose}
              style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                padding: "0",
                height: "20px",
                width: "20px",
              }}
            >
              <img
                style={{
                  maxWidth: "20px",
                  objectFit: "contain",
                }}
                src={CloseModalIcon}
                alt="close modal"
              />
            </button>
          </Box>
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            flexDirection={"column"}
            gap={2}
          >
            <Box>
              <InputLabel
                sx={{
                  fontSize: 11,
                  fontFamily: "Inter",
                  lineHeight: "16px",
                  letterSpacing: "0.4px",
                  color: "#DCDEE4",
                  top: 0,
                }}
                htmlFor="oldPassword"
              >
                Old password
              </InputLabel>
              <StyledAuthInputFields
                className="password-fields"
                value={oldPassword}
                onChange={onChangeHandler}
                type={showOldPassword ? "text" : "password"}
                name={"oldPassword"}
                sx={{
                  borderRight: "none",
                  width: "100%",
                  paddingRight: "0",
                  input: {
                    borderRight: "none",
                    borderRadius: "4px 0 0 4px",
                    color: showOldPassword
                      ? "rgba(252, 252, 252, 1)"
                      : "rgba(252, 252, 252, .6)",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        border: "solid 1px rgba(252,252,252, 0.1)",
                        borderLeft: "none",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        marginLeft: 0,
                        padding: "18.6px",
                        paddingLeft: "0",
                      }}
                      position="end"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("oldPassword")}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseDownPassword}
                        edge="end"
                      >
                        {showOldPassword ? (
                          <VisibilityOffOutlined
                            sx={{
                              height: "15px",
                              width: "15px",
                              color: "rgba(252, 252, 252, 0.6)",
                            }}
                          />
                        ) : (
                          <VisibilityOutlined
                            sx={{
                              height: "15px",
                              width: "15px",
                              color: "rgba(252, 252, 252, 0.6)",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {oldPasswordError && (
                <Typography fontSize={12} color={"error"} variant={"body1"}>
                  {oldPasswordError}
                </Typography>
              )}
            </Box>

            <Box>
              <InputLabel
                sx={{
                  fontSize: 11,
                  fontFamily: "Inter",
                  lineHeight: "16px",
                  letterSpacing: "0.4px",
                  color: "#DCDEE4",
                  top: 0,
                }}
                htmlFor="newPassword"
              >
                New Password
              </InputLabel>
              <StyledAuthInputFields
                value={newPassword}
                className="password-fields"
                onChange={onChangeHandler}
                type={showNewPassword ? "text" : "password"}
                name={"newPassword"}
                sx={{
                  borderRight: "none",
                  width: "100%",
                  input: {
                    borderRight: "none",
                    borderRadius: "4px 0 0 4px",
                    color: showNewPassword
                      ? "rgba(252, 252, 252, 1)"
                      : "rgba(252, 252, 252, .6)",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        border: "solid 1px rgba(252,252,252, 0.1)",
                        borderLeft: "none",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        marginLeft: 0,
                        padding: "18.6px",
                        paddingLeft: "0",
                      }}
                      position="end"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("newPassword")}
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseDownPassword}
                        edge="end"
                      >
                        {showNewPassword ? (
                          <VisibilityOffOutlined
                            sx={{
                              height: "15px",
                              width: "15px",
                              color: "rgba(252, 252, 252, 0.6)",
                            }}
                          />
                        ) : (
                          <VisibilityOutlined
                            sx={{
                              height: "15px",
                              width: "15px",
                              color: "rgba(252, 252, 252, 0.6)",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {newPasswordError && (
                <Typography fontSize={12} color={"error"} variant={"body1"}>
                  {newPasswordError}
                </Typography>
              )}
            </Box>

            <Box>
              <InputLabel
                sx={{
                  fontSize: 11,
                  fontFamily: "Inter",
                  lineHeight: "16px",
                  letterSpacing: "0.4px",
                  color: "#DCDEE4",
                  top: 0,
                }}
                htmlFor="confirmPassword"
              >
                Confirm Password
              </InputLabel>
              <StyledAuthInputFields
                value={confirmPassword}
                className="password-fields"
                onChange={onChangeHandler}
                type={showConfirmPassword ? "text" : "password"}
                name={"confirmPassword"}
                sx={{
                  borderRight: "none",
                  width: "100%",
                  input: {
                    borderRight: "none",
                    borderRadius: "4px 0 0 4px",
                    color: showConfirmPassword
                      ? "rgba(252, 252, 252, 1)"
                      : "rgba(252, 252, 252, .6)",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      sx={{
                        border: "solid 1px rgba(252,252,252, 0.1)",
                        borderLeft: "none",
                        borderTopRightRadius: "4px",
                        borderBottomRightRadius: "4px",
                        marginLeft: 0,
                        padding: "18.6px",
                        paddingLeft: "0",
                      }}
                      position="end"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          handleClickShowPassword("confirmPassword")
                        }
                        onMouseDown={handleMouseDownPassword}
                        onMouseUp={handleMouseDownPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOffOutlined
                            sx={{
                              height: "15px",
                              width: "15px",
                              color: "rgba(252, 252, 252, 0.6)",
                            }}
                          />
                        ) : (
                          <VisibilityOutlined
                            sx={{
                              height: "15px",
                              width: "15px",
                              color: "rgba(252, 252, 252, 0.6)",
                            }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {confirmPasswordError && (
                <Typography fontSize={12} color={"error"} variant={"body1"}>
                  {confirmPasswordError}
                </Typography>
              )}
            </Box>
          </Box>
          <Divider
            sx={{
              width: "100%",
            }}
          />
          <Box display={"flex"} justifyContent={"center"}>
            <StyledAuthButton
              variant={"contained"}
              sx={{
                width: "100%",
                mx: "auto",
                marginBottom: "7px",
                marginTop: "10px",
              }}
              onClick={submitHandler}
            >
              Continue
            </StyledAuthButton>
          </Box>
        </StyledAccountContent>
      </Box>
    </Modal>
  );
};

export default ChangePasswordModal;
