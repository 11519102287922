// Initial state
const initialState = {
  events: [],
};

// Action Types
const ADD_ANALYTICS_EVENT = 'ADD_ANALYTICS_EVENT';

// Action Creators
export const addAnalyticsEvent = (event: any) => ({
  type: ADD_ANALYTICS_EVENT,
  payload: event,
});

const analyticsReducer = (state = initialState, action: any) => {
  if (action.type.startsWith('DDA_')) {
    return {
      ...state,
      events: [...state.events, action.payload],
    };
  }
  switch (action.type) {
    // TODO: Extend this reducer to handle more actions  
    default:
      return state;
  }
};

export default analyticsReducer;