import { styled, Box, BoxProps } from '@mui/material';



export const StyledSidebarContainer = styled(Box)<BoxProps>(({ }) => ({
  maxWidth: 'clamp(15%, 10vw, 300px)',
  width: '100%',
  backgroundColor: '#1C2536',
  height: '100%',
  padding: 16,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  h4: {
    color: '#cecece',
    textAlign: 'center',
  },
  gap: 16,
}));
