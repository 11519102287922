import axios from "axios";
import { router } from "..";

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const api = axios.create({
  baseURL: "https://api.density.one/api",
  // baseURL: "https://qa.api.density.one/api",
  // baseURL: "http://localhost:8080/api",
  headers: {
    "Content-type": "application/json",
  },
});

const unprotectedApi = axios.create({
  baseURL: "https://api.density.one/api",
    // baseURL: "https://qa.api.density.one/api",
  // baseURL: "http://localhost:8080/api",
  headers: {
    "Content-type": "application/json",
  },
});

api.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const { status } = error.response;
    const refresh_token = localStorage.getItem("refresh_token");

    if (!refresh_token) {
      return Promise.reject(error);
    }

    if (
      (status === FORBIDDEN || status === UNAUTHORIZED) &&
      !originalRequest._retry
    ) {
      try {
        // insert path to refresh token
        const tokens = await api.post("/auth/refresh", {
          refresh_token,
        });
        localStorage.setItem("access_token", tokens.data.access_token);
        localStorage.setItem("refresh_token", tokens.data.refresh_token);
        originalRequest._retry = true;

        api.defaults.headers.Authorization = tokens.data.token;

        return api(originalRequest);
      } catch (err: any) {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user_id");
        window.location.href = "/login";
      }
    }
    return Promise.reject(error);
  },
);

export { api, unprotectedApi };
