import { all } from 'redux-saga/effects';
import { analyticsSaga } from './analytics';
import { appSaga } from './app';
// Import other sagas as needed

export default function* rootSaga() {
  yield all([
    analyticsSaga(),
    appSaga()
    // Other sagas
  ]);
}