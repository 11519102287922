import React from "react";
import arrowX from "../../assets/close 20.svg";

interface ModalProps {
  show: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  title: string;
  message: string;
}

const WaitlistModal: React.FC<ModalProps> = ({
  show,
  onConfirm,
  onCancel,
  title,
  message,
}) => {
  if (!show) {
    return null;
  }

  return (
    <>
      <div className="modal-backdrop" onClick={onCancel} />
      <div className="modal-container">
        <div className="modal-header">
          <span className="modal-title">{title}</span>
          <button className="modal-close-button" onClick={onCancel}>
            <img src={arrowX} />
          </button>
        </div>
        <div className="modal-message py-5">{message}</div>
        <div className="modal-buttons">
          <button className="modal-button confirm" onClick={onConfirm}>
            Join Windows Waitlist
          </button>
          <button className="modal-button cancel" onClick={onCancel}>
            Continue on Mac
          </button>
        </div>
      </div>
    </>
  );
};

export default WaitlistModal;