import { Typography, Box } from "@mui/material";
import { useDispatch } from "react-redux";

import { api } from "../../api/base";
import { analyticsEvent } from "../../redux/actions/analytics";

import Header from "../../components/Header";
import {
  StyledAuthButton,
  StyledAuthInputs,
  StyledDashContainer,
} from "../../components/micro/micro.styled";

import FinalStepImage from "../../assets/Main screen active.png";

const DownloadPage = () => {
  const dispatch = useDispatch();

  const handleDownload = () => {
    dispatch(
      analyticsEvent("EVENT", "Download Density App", {
        "OS Downloaded": "Universal",
        location: "Account Management Page",
      }),
    );

    api
      .post("/auth/download", {
        email: localStorage.getItem("user_email_login") || "",
        os: "universal",
      })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <StyledDashContainer>
      <Header />
      <div
        style={{
          contain: "layout",
        }}
        className="grid h-[calc(100%-91px)] w-full grid-cols-1 gap-[80px] md:mt-[89px] md:h-[100%] md:w-[100vw] md:grid-cols-final lg:h-auto 3xl:max-w-[1800px]"
      >
        <div className="">
          <StyledAuthInputs
            sx={{
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <div className="block">
              <Box
                sx={{
                  maxWidth: "400px",
                }}
              >
                <Typography
                  textAlign={"center"}
                  fontSize={"20px"}
                  variant={"h5"}
                  mb={2}
                  fontWeight={500}
                  color={"rgba(252, 252, 252, 1)"}
                >
                  Download Density app
                </Typography>
                <Typography
                  fontSize={"12px"}
                  fontWeight={500}
                  textAlign={"center"}
                  variant={"body1"}
                  color={"rgba(252, 252, 252, 0.6)"}
                  maxWidth={275}
                  mx={"auto"}
                  lineHeight={"18px"}
                  letterSpacing={"0.1px"}
                >
                   Mac only, current version 1.245
                </Typography>
              </Box>
              <Box sx={{ mt: "45px" }}>
                <a
                  href="https://release.density.one/Density-Installer-Universal-Latest.dmg"
                  download
                >
                  <StyledAuthButton
                    onClick={handleDownload}
                    variant={"contained"}
                    sx={{
                      mx: 0,
                      mb: 2,
                    }}
                  >
                    Download App
                  </StyledAuthButton>
                </a>
              </Box>
            </div>
          </StyledAuthInputs>
        </div>
        <div className="mx-auto hidden w-[80%] md:block md:w-[1350px]">
          <img
            className="overflow-clip object-contain"
            src={FinalStepImage}
            alt="Final Step"
          />
        </div>
      </div>
    </StyledDashContainer>
  );
};

export default DownloadPage;
