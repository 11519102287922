import { Typography, InputLabel, Box } from "@mui/material";
import { WaitlistProps } from "../../types/waitlist";
import {
  StyledAuthInputs,
  StyledAuthInputFields,
  StyledAuthButton,
} from "../micro/micro.styled";

import TickCircle from "../../assets/tick-circle.svg";

const InstagramStep = (props: WaitlistProps): JSX.Element => {
  const {
    onChangeHandler,
    userData,
    nextUserFunction,
    formError,
    instagramHasInput,
    onNextKeyPress,
  } = props;

  return (
    <StyledAuthInputs>
      <Typography
        variant={"h2"}
        mb={5}
        mt={6}
        align={"left"}
        maxWidth={"230px"}
      >
        Density is for Pro DJs. Verify your Instagram.
      </Typography>
      <Box sx={{ position: "relative" }}>
        <InputLabel sx={{ top: 0 }} htmlFor="instagramLink">
          Link to Instagram Account
        </InputLabel>
        <StyledAuthInputFields
          value={userData.instagramLink || userData.soundcloudURL}
          onChange={onChangeHandler}
          type={"text"}
          name={"instagramLink"}
          autoComplete="off"
          placeholder={`${
            instagramHasInput ? "" : "https://instagram.com/username"
          }`}
          sx={{
            paddingBottom: "18px",
            width: "100%",
            "& .Mui-focused": {
              boxShadow: "none",
              caretColor: "#575F9E !important",
              outline: "1px solid #575F9E",
            },
            "&.Mui-focused": {
              boxShadow: "none",
              caretColor: "#575F9E !important",
              outline: "1px solid #575F9E",
            },
          }}
          error={formError !== ""}
          onKeyDown={onNextKeyPress}
        />
        {!instagramHasInput && (
          <span
            style={{
              position: "absolute",
              left: "108.5px",
              color: "rgba(252, 252, 252, 0.60)",
              pointerEvents: "none",
              top: "31.5px",
              fontSize: "12px",
            }}
          >
            username
          </span>
        )}
        {formError && (
          <Typography mt={-2} fontSize={12} color={"error"} variant={"body1"}>
            {formError}
          </Typography>
        )}
        {instagramHasInput && (
          <img
            style={{
              height: "16px",
              width: "16px",
              objectFit: "contain",
              position: "absolute",
              top: "35.5px",
              right: "16px",
            }}
            src={TickCircle}
            alt=""
          />
        )}
      </Box>
      <StyledAuthButton
        sx={{
          marginTop: {
            sm: "45px",
          },
        }}
        onClick={() => nextUserFunction()}
        variant={"contained"}
        disabled={
          userData.instagramLink === "instagram.com/" ||
          userData.soundcloudURL === "instagram.com/" ||
          formError !== ""
        }
      >
        Verify
      </StyledAuthButton>
    </StyledAuthInputs>
  );
};

export default InstagramStep;
