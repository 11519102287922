import React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

export const ColorModeContext = React.createContext({
  toggleColorMode: () => {},
});

export const ColorModeProvider = ({ children }: any): JSX.Element => {
  const [mode, setMode] = React.useState<"light" | "dark">("dark");

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode: "light" | "dark") =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: [
            "PPRightGrotesk-Bold",
            "PPRightGrotesk-Medium",
            "PPRightGrotesk-WideMedium",
            "Roboto",
            "Helvetica Neue",
            "IBM Plex Mono",
            "Arial",
            "sans-serif",
          ].join(","),

          h1: {
            fontFamily: "PPRightGrotesk-Bold",
            fontSize: "60px",
            fontStyle: "normal",
            lineHeight: "68.55px",
            color: "#C1C5DD",
            marginTop: "0px",
            marginBottom: "0px",
            textAlign: "center",
            background:
              "linear-gradient(180deg, rgba(255,255,255,1) -35%, rgba(112,117,196,1) 100%)",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "transparent",
          },
          subtitle2: {
            fontFamily: "PPRightGrotesk-Bold",
            fontSize: "35px",
            lineHeight: "40px",
            color: "#C1C5DD",
          },
          h2: {
            fontFamily: "Inter",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "24px",
            letterSpacing: "0.15px",
            color: "#FDFDFD",
          },
          h3: {},
          h4: {},
          h5: {
            color: "#FFFFFF",
            fontSize: "34px",
            lineHeight: "130%",
            fontWeight: "400",
            letterSpacing: "0.1px",
            fontFamily: '"Inter", sans-serif',
            fontStyle: "normal",
          },
          subtitle1: {
            fontSize: "14px",
            lineHeight: "24px",
            letterSpacing: "0.1px",
            color: "rgba(255, 255, 255, 0.6)",
            fontFamily: '"Inter", sans-serif',
          },
          body1: {
            color: "rgba(255, 255, 255, 0.6)",
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: "500",
            letterSpacing: "0.1px",
            textAlign: "left",
            fontFamily: '"Inter", sans-serif',
          },

          body2: {
            color: "rgba(255, 255, 255, 0.3)",
            fontSize: "14px",
            lineHeight: "24px",
            fontWeight: "500",
            letterSpacing: "0.1px",
            fontFamily: '"Inter", sans-serif',
          },
          button: {
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: "700",
            color: "#121215",
            padding: "10px 12px 10px 14px",
          },
        },
        palette: {
          mode,
          primary: {
            main: "#FFFFFF",
            dark: "#FFFFFF",
            light: "#4856CE",
          },
          secondary: {
            main: "#121215;",
            dark: "#141519",
          },
          error: {
            main: "#F37075",
          },
          success: {
            main: "#048848",
          },
          background: {
            default: "#20232B",
            paper: "#2C2C2C",
          },
        },
        components: {
          MuiButton: {
            styleOverrides: {
              contained: {
                color: "#121215",
                backgroundColor: "#FFF",
                padding: "10px 14px",
                fontFamily: "IBM Plex Mono",
                letterSpacing: "1px",
                ":hover": {
                  backgroundColor: "#DCDEE4",
                },
                ":active": {
                  backgroundColor: "#9EA1B2",
                },
                ":disabled": {
                  backgroundColor: "rgba(252, 252, 252, 0.1)",
                  color: "rgba(252, 252, 252, 0.15)",
                },
              },
              outlined: {
                color: "rgba(255, 255, 255, 0.6)",
                border: "solid 1px rgba(255, 255, 255, 0.6)",
                backgroundColor: "transparent",
                fontFamily: "IBM Plex Mono",
              },
            },
          },
          MuiTextField: {
            styleOverrides: {
              root: {
                input: {
                  padding: "10px 16px",
                  color: "#FCFCFC",
                  fontSize: "12px",
                  backgroundColor: "rgba(2,2,3, 0.3)",
                  border: "solid 1px rgba(252,252,252, 0.1)",
                  outline: "solid 1px transparent",
                  fieldset: {
                    "&:hover ": {
                      border: "none",
                      outline: "none",
                    },
                  },
                  ":-webkit-autofill": {
                    boxShadow: "0 0 0 100px rgba(2,2,3,0.5) inset",
                  },
                },
              },
            },
          },
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                "&.Mui-focused": {
                  outline: "none",
                  border: "none",
                },
                "&.Mui-error": {
                  input: {
                    boxShadow: "0 0 0 100px rgba(2,2,3,0.3) inset",
                  },
                },
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                textAlign: "left",
                top: "15px",
                color: "#FFFFFF",
                marginBottom: "4px",
              },
            },
          },
          MuiCollapse: {
            styleOverrides: {
              hidden: {
                display: "none",
              },
            },
          },
          MuiAlert: {
            styleOverrides: {
              filledSuccess: {
                backgroundColor: "#008600",
              },
            },
          },
        },
      }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};
