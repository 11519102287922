import { ArrowBackIos } from "@mui/icons-material";

import {
  StyledAuthButton,
  StyledAuthHeader,
  StyledAuthInputs,
} from "../../components/micro/micro.styled";
import { Box, Typography } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import useWindowDimensions from "../../utils/hooks/windowDimensionsHook";

import NewDensityLogo from "../../assets/Density app logo.svg";

import FinalStepImage from "../../assets/Main screen active.png";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { analyticsEvent } from "../../redux/actions/analytics";

const UpgradeSuccessPage = () => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const isHardware = searchParams.get("plan") === "hardware";
  const backButtonHandler = () => {
    navigate("/account");
  };

  useEffect(() => {
    dispatch(
      analyticsEvent("PAGE_VISIT", "Confirmation Page", {
        plan: "premium",
      }),
    );
  }, []);

  const handleButtonClick = () => {
    if (searchParams.get("fromDesktop") === "true") {
      window.open("density://", "_blank");
      backButtonHandler();
    } else {
      backButtonHandler();
    }
  };

  const steps = ["1", "2", "3", "4"];

  return (
    <div className="flex h-[100vh] w-full flex-col justify-between overflow-hidden bg-[#141519] md:h-auto lg:h-full">
      <StyledAuthHeader>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: "500px",
            mx: "auto",
            position: "relative",
          }}
        >
          <Box
            sx={{
              cursor: "pointer",
              position: "absolute",
              left: "0",
            }}
            onClick={backButtonHandler}
          >
            <ArrowBackIos />
          </Box>
          <img
            onClick={() => navigate("/")}
            style={{ width: "175px", height: "54px", objectFit: "contain" }}
            src={NewDensityLogo}
            alt={"Density"}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "10px",
            gap: "10px",
            justifyContent: "space-between",
            maxWidth: "500px",
          }}
          mt={2}
          mx={"auto"}
        >
          {steps.map((stepNumber: string) => (
            <Box
              key={stepNumber}
              sx={{
                py: "8px",
                width: width! <= 500 ? "45px" : "106px",
                borderRadius: "4px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Box
                sx={{
                  height: "3px",
                  flexShrink: 0,
                  width: width! <= 500 ? "45px" : "106px",
                  borderRadius: "4px",
                  backgroundColor: "#fcfcfc",
                }}
              ></Box>
            </Box>
          ))}
        </Box>
      </StyledAuthHeader>
      <div
        style={{
          contain: "layout",
        }}
        className="grid w-full grid-cols-1 gap-[80px] md:mt-[89px] md:w-[100vw] md:grid-cols-final 3xl:max-w-[1800px]"
      >
        <div className="">
          <StyledAuthInputs
            sx={{
              margin: "auto",
              justifyContent: "center",
            }}
          >
            <Box>
              <Typography
                textAlign={"center"}
                fontSize={"20px"}
                variant={"h5"}
                mb={2}
                fontWeight={500}
                color={"rgba(252, 252, 252, 1)"}
              >
                {isHardware ? "Your order is on the way!" : "You're on Density Premium!"}
              </Typography>
              <Typography
                fontSize={"12px"}
                fontWeight={500}
                textAlign={"center"}
                variant={"body1"}
                color={"rgba(252, 252, 252, 0.6)"}
                maxWidth={275}
                mx={"auto"}
                lineHeight={"18px"}
                letterSpacing={"0.1px"}
              >
                We hope you make some great sets.
              </Typography>
            </Box>
            <Box sx={{ mt: "45px" }}>
              <StyledAuthButton
                onClick={handleButtonClick}
                variant={"contained"}
                sx={{
                  mx: 0,
                  mb: 2,
                }}
              >
                {searchParams.get("fromDesktop") === "true"
                  ? "Open Density App"
                  : "Back to Account"}
              </StyledAuthButton>
            </Box>
          </StyledAuthInputs>
        </div>
        <div className="mx-auto w-[80%] md:w-[1350px]">
          <img
            className="overflow-clip object-contain"
            src={FinalStepImage}
            alt="Final Step"
          />
        </div>
      </div>
    </div>
  );
};

export default UpgradeSuccessPage;
