import { useEffect, useState } from "react";
import { DeviceType, OS } from "../../types/globals";

const getFullWidth = () => {
    if (typeof window !== "undefined" && typeof window.screen !== "undefined") {
        return window.screen.width;
    }
    return 0;
};

const getDeviceInfo = () => {
    const userAgent = typeof navigator !== "undefined" ? navigator.userAgent.toLowerCase() : "";
    const deviceWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    const fullWidth = getFullWidth();
    let type: DeviceType = DeviceType.Desktop;
    let os: OS = OS.Unknown;
    const isIPad = (/macintosh/.test(userAgent) && navigator.maxTouchPoints > 0) || /ipad/.test(userAgent);

    if (/macintosh|mac os x/.test(userAgent)) {
        os = OS.MacOS;
    } else if (/windows/.test(userAgent)) {
        os = OS.Windows;
    } else if (/android/.test(userAgent)) {
        os = OS.Android;
    } else if (/iphone|ipad|ipod/.test(userAgent)) {
        os = OS.IOS;
    }

    if (isIPad || /mobile|android|iphone|ipad/.test(userAgent)) {
        if (isIPad || /ipad|tablet/.test(userAgent) || (navigator.maxTouchPoints && navigator.maxTouchPoints > 1)) {
            type = DeviceType.Tablet;
        } else {
            type = DeviceType.Mobile;
        }
    } else if (fullWidth < 767) {
        type = DeviceType.Mobile;
    } else if (fullWidth <= 1024 && os !== OS.MacOS) {
        type = DeviceType.Tablet;
    } else {
        type = DeviceType.Desktop;
    }


    return { type, os, deviceWidth, fullWidth };
};

const useDeviceInfo = () => {
    const [deviceType, setDeviceType] = useState(getDeviceInfo());

    useEffect(() => {
        const handleResize = () => {
            setDeviceType(getDeviceInfo());
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return deviceType;
};

export default useDeviceInfo;
