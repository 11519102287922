import React, { useEffect, useState } from "react";

import {
  Button,
  TextField,
  Typography,
  InputLabel,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { AuthProps } from "../../types/auth";
import {
  StyledAuthButton,
  StyledAuthInputFields,
  StyledAuthInputs,
  StyledPasswordChecklist,
} from "../micro/micro.styled";

import { useSnackBar } from "../../context/SnackBar";

import ValidPasswordCheckIcon from "../../assets/ValidPasswordCheck.svg";
import InvalidPasswordCheckIcon from "../../assets/InvalidPasswordCheck.svg";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const CreatePassword = (props: AuthProps): JSX.Element => {
  const { onChangeHandler, userData, nextUserFunction, onNextKeyPress } = props;
  const { showSnackBar } = useSnackBar();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let { refId } = useParams();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [enableButton, setEnableButton] = useState<boolean>(true);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (searchParams.get("success")) {
      localStorage.setItem("madePayment", "true");
      showSnackBar(
        "Welcome to Density. You’ve successfully subscribed to Density.",
        "success",
        "",
        2000,
        true,
      );

      // (window as any).fbq("trackSingle", "1506138416630958", "StartTrial", {
      //   value: "0.00",
      //   currency: "USD",
      //   predicted_ltv: "15.00",
      // });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("onboarding_in_progress", "true");
  }, []);

  return (
    <StyledAuthInputs>
      <Typography textAlign={"left"} variant={"h2"} maxWidth={"200px"}>
        Choose a password for your account
      </Typography>
      <InputLabel htmlFor="password">Password</InputLabel>
      <StyledAuthInputFields
        value={userData.password}
        onChange={onChangeHandler}
        onKeyDown={onNextKeyPress}
        type={showPassword ? "text" : "password"}
        name={"password"}
        sx={{
          borderRight: "none",
          input: {
            borderRight: "none",
            borderRadius: "4px 0 0 4px",
            color: showPassword
              ? "rgba(252, 252, 252, 1)"
              : "rgba(252, 252, 252, .6)",
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{
                border: "solid 1px rgba(252,252,252, 0.1)",
                borderLeft: "none",
                borderTopRightRadius: "4px",
                borderBottomRightRadius: "4px",
                marginLeft: 0,
                padding: "18.6px",
                paddingLeft: "0",
              }}
              position="end"
            >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOffOutlined
                    sx={{
                      height: "15px",
                      width: "15px",
                      color: "rgba(252, 252, 252, 0.6)",
                    }}
                  />
                ) : (
                  <VisibilityOutlined
                    sx={{
                      height: "15px",
                      width: "15px",
                      color: "rgba(252, 252, 252, 0.6)",
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Typography
        textAlign={"left"}
        fontSize={11}
        color={"rgba(252, 252, 252, 0.40)"}
        variant={"body2"}
      >
        Create a password that:
      </Typography>
      <StyledPasswordChecklist
        rules={["minLength", "specialChar", "number", "capital"]}
        minLength={8}
        value={userData.password}
        onChange={(isValid) => {
          isValid ? setEnableButton(false) : setEnableButton(true);
        }}
        invalidColor={"rgba(252, 252, 252, 0.80)"}
        validColor={"#00BE00"}
        iconSize={10}
        style={{
          alignItems: "center",
        }}
        iconComponents={{
          ValidIcon: (
            <img
              style={{
                height: 20,
                width: 20,
                objectFit: "contain",
              }}
              src={ValidPasswordCheckIcon}
              alt="valid input"
            />
          ),
          InvalidIcon: (
            <img
              style={{
                height: 20,
                width: 20,
                objectFit: "contain",
              }}
              src={InvalidPasswordCheckIcon}
              alt="invalid input"
            />
          ),
        }}
      />
      <StyledAuthButton
        sx={{
          marginTop: {
            sm: "45px",
          },
        }}
        onClick={() => nextUserFunction()}
        disabled={enableButton}
        variant={"contained"}
      >
        Save and continue
      </StyledAuthButton>
    </StyledAuthInputs>
  );
};

export default CreatePassword;
