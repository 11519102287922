import { Snackbar, Alert, AlertColor, Slide } from "@mui/material";
import CheckCircleIcon from "../assets/CheckCircle.svg";

import React, { createContext, useContext, useEffect } from "react";
import { useTheme } from "@mui/material";

type SnackBarContextActions = {
  showSnackBar: (
    text: string,
    typeColor: AlertColor,
    justifyContent?: string,
    duration?: number,
    isCreatePassword?: boolean,
    width?: string,
    topOffset?: string,
  ) => void;
};

const SnackBarContext = createContext({} as SnackBarContextActions);

interface SnackBarContextProviderProps {
  children: React.ReactNode;
}

const SnackBarProvider: React.FC<SnackBarContextProviderProps> = ({
  children,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [typeColor, setTypeColor] = React.useState<AlertColor>("info");
  const [justifyContent, setJustifyContent] = React.useState<string>("initial");
  const [customDuration, setCustomDuration] = React.useState<number>(2000);
  const [largerPadding, setLargerPadding] = React.useState<boolean>(false);
  const [snackbarWidth, setSnackbarWidth] = React.useState<string>("100%");
  const [snackbarTopOffset, setSnackbarTopOffset] = React.useState<string>("24px");

  const showSnackBar = (
    text: string,
    color: AlertColor,
    justifyContent: string = "initial",
    duration: number = 2000,
    isCreatePassword: boolean = false,
    width: string = "100%",
    topOffset: string = "24px",
  ) => {
    setMessage(text);
    setTypeColor(color);
    setOpen(true);
    setJustifyContent(justifyContent);
    setCustomDuration(duration);
    setLargerPadding(isCreatePassword);
    setSnackbarWidth(width);
    setSnackbarTopOffset(topOffset);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function TransitionDown(props: any) {
    return (
      <Slide {...props} in={open} direction="down" mountOnEnter unmountOnExit />
    );
  }

  return (
    <SnackBarContext.Provider value={{ showSnackBar }}>
			{open && (
				<React.Fragment>
					<Snackbar
						open={open}
						autoHideDuration={customDuration}
						sx={{
							width: "100%",
							left: "0",
							right: "0",
							top: `${snackbarTopOffset} !important`,
						}}
						TransitionComponent={TransitionDown}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						onClose={handleClose}
					>
						<Alert
							severity={typeColor}
							icon={typeColor === "success" &&
								<img
									src={CheckCircleIcon}
									alt="check circle"
									style={{
										height: "20px",
										width: "20px",
										marginLeft: justifyContent === "center" ? "0" : "10px",
										marginRight: justifyContent === "center" ? "0" : "10px",
									}}
								/>
							}
							sx={{
								width: "calc(100% - 60px)",
								justifyContent,
								alignItems: "center",
								padding: `${largerPadding} '11px 25px' : '4px 25px'`,
								"& .MuiAlert-message": {
									fontFamily: "Inter",
									fontSize: "12px",
									fontWeight: 500,
									lineHeight: "18px",
									marginLeft: justifyContent === "center" ? "0" : "10px",
									color: '#FCFCFC'
								},
								[theme.breakpoints.up("md")]: {
									width: snackbarWidth,
								},
							}}
							variant="filled"
						>
							{message}
						</Alert>
					</Snackbar>
				</React.Fragment>
			)}
      {children}
    </SnackBarContext.Provider>
  );
};

const useSnackBar = (): SnackBarContextActions => {
  const context = useContext(SnackBarContext);

  if (!context) {
    throw new Error("useSnackBar must be used within an SnackBarProvider");
  }

  return context;
};

export { SnackBarProvider, useSnackBar };
